.navigate-widget {
  --border-color: #f0f0f0;
  --gutter: 20px;
  display: flex;
  margin-bottom: 2em;
  gap: var(--gutter);
}

.navigate-widget-title {
  color: white;
  font-size: 1.5em;
  padding: 1em 0.2em;
  margin: 0;
  writing-mode: vertical-lr;
  text-align: center;
  transform: rotate(180deg);
  background-color: hsl(var(--skayl-violet-hs) 30%);
}

.navigate-widget-sections {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: var(--gutter);
}

.navigate-widget-section {
  display: flex;
  flex-direction: column;
  flex: 0 0 419px;
  border: 2px solid var(--border-color);
}

.navigate-widget-section header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5em;
  gap: 1em;
  background-color: var(--border-color);
}

.navigate-widget-section header h2 {
  font-size: 1em;
  margin: 0;
}


.navigate-widget-section .navigate-widget-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  margin: 0;
}

.navigate-widget-section a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 1em;
  width: 100%;
  flex: 1;
}

.navigate-widget-section a:after {
  display: block;
  position: absolute;
  content: "";
  height: 1px;
  width: 80%;
  bottom: 0;
  background-color: var(--border-color);
}

.navigate-widget-section a:hover {
  text-decoration: none;
  background-color: var(--border-color);
}

.navigate-widget-section li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  flex: 1;
}

.navigate-widget-section li:after {
  content: "";
  height: 1px;
  width: 80%;
  background: var(--border-color);
}

.navigate-widget-section li:last-child {
  border-bottom: none;
}



.navigate-play {
  color: hsl(var(--skayl-purple-hs) 62%);
  border: 2px solid hsl(var(--skayl-purple-hs) 62%);
  border-radius: 50%;
  line-height: 1.5;
}
