:root {
    --sp-handle-size: 9px;
}



/*  
    ==========
    SCRATCHPAD 
    ==========
*/
.srd-demo-canvas {
    height: 100%;
    cursor: auto !important;
    /* overflow: auto !important; */
}

/* .srd-demo-canvas > svg:first-of-type {
    z-index: 1;
}

.srd-demo-canvas > div:first-of-type {
    z-index: 0;
} */

.srd-demo-canvas .node {
    z-index: 0;
}

.srd-demo-canvas g {
    z-index: 0;
}

/* TODO: do i need this? */
.srd-demo-canvas > svg {
    overflow: visible !important;
}

/* TODO: what is this? */
.srd-demo-canvas .pointui {
    fill: rgba(255, 255, 255, 0.5);
}

.diagram-nav {
    height: 35px;
    display: flex;
    align-items: flex-end;
    background-color: rgb(138, 163, 178);
}

.diagram-nav > span {
    background-color: #b8b8b8;
    border-top-left-radius: 2px;
    border-top-right-radius: 6px;
    padding: 0 5px;
    margin: 0 5px;
    color: #fff;
    cursor: pointer;
}

.diagram-nav span.active {
    background-color: #747575;
}

.diagram-nav span:disabled {
    background-color: #ccc;
}

.diagram-nav button {
    outline: none;
    border: none;
    padding: 1px;
    margin: 3px;
    border-radius: 50%;
    color: black;
    line-height: 0;
}

.stencil-box {
    background-color: var(--color-sidebar);
    padding: 10px;
    overflow: hidden;
}

.side-panel-controls {
    background-color: var(--color-sidebar);
}

.graph-header {
    position: relative;
    /* height: 56px; */
    width: 100%;
}

.graph-container {
    top: 0;
    position: relative;
    height: calc(100vh - 259px);
    width: 100%;
    /* left: 110px; */
    right: 0;
    overflow: scroll;
}

.joint-theme-modern .toggle > #first {
    height: 16px;
    width: 16px;
    margin-top: -2px;
}

.joint-theme-modern .toggle .tglLeft {
    right: calc(100% - 16px);
}




/* 
    ==========
    NODES 
    ==========
*/
.modelling,
.projection {
    background: #F6F6F6;
}

.modelling .modelling-link,
.modelling .diagram-label,
.projection .tool-link {
    display: block;
}
 
.projection .projection-link {
    display: block;
}

.modelling-link,
.diagram-label,
.projection-link {
    display: none;
}


/* 
    ==========
    DRAG N DROP 
    ==========
*/
.drop-zone {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  justify-content: space-between;
  z-index: 1;
}

.leaf-drag .drop-zone,
.tool-drag .drop-zone,
.arrow-comp-drag .drop-zone,
.arrow-path-drag .drop-zone {
    display: flex;
}

.arrow-path-drag .path-placement,
.arrow-comp-drag .comp-placement,
.tool-drag:hover .comp-placement {
    display: flex;
}

.comp-placement,
.path-placement {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: var(--sp-handle-size);
    height: var(--sp-handle-size);
    border-radius: 30px;
    background: var(--skayl-grey);
}

.drop-zone .top-left { top: calc(var(--sp-handle-size) / -2); left: calc(var(--sp-handle-size) / -2); }
.drop-zone .top { top: calc(var(--sp-handle-size) / -2); left: calc(50% - var(--sp-handle-size) / 2); }
.drop-zone .top-right { top: calc(var(--sp-handle-size) / -2); right: calc(var(--sp-handle-size) / -2); }
.drop-zone .left { top: calc(50% - var(--sp-handle-size) / 2); left: calc(var(--sp-handle-size) / -2); }
.drop-zone .right { top: calc(50% - var(--sp-handle-size) / 2); right: calc(var(--sp-handle-size) / -2); }
.drop-zone .bottom-left { bottom: calc(var(--sp-handle-size) / -2); left: calc(var(--sp-handle-size) / -2); }
.drop-zone .bottom { bottom: calc(var(--sp-handle-size) / -2); left: calc(50% - var(--sp-handle-size) / 2); }
.drop-zone .bottom-right { bottom: calc(var(--sp-handle-size) / -2); right: calc(var(--sp-handle-size) / -2); }

.drop-zone .top-0 { top: calc(var(--sp-handle-size) / -2); left: calc(0% - var(--sp-handle-size) / 2); }
.drop-zone .top-1 { top: calc(var(--sp-handle-size) / -2); left: calc(10% - var(--sp-handle-size) / 2); }
.drop-zone .top-3 { top: calc(var(--sp-handle-size) / -2); left: calc(20% - var(--sp-handle-size) / 2); }
.drop-zone .top-2 { top: calc(var(--sp-handle-size) / -2); left: calc(30% - var(--sp-handle-size) / 2); }
.drop-zone .top-4 { top: calc(var(--sp-handle-size) / -2); left: calc(40% - var(--sp-handle-size) / 2); }
.drop-zone .top-5 { top: calc(var(--sp-handle-size) / -2); left: calc(50% - var(--sp-handle-size) / 2); }
.drop-zone .top-6 { top: calc(var(--sp-handle-size) / -2); left: calc(60% - var(--sp-handle-size) / 2); }
.drop-zone .top-7 { top: calc(var(--sp-handle-size) / -2); left: calc(70% - var(--sp-handle-size) / 2); }
.drop-zone .top-8 { top: calc(var(--sp-handle-size) / -2); left: calc(80% - var(--sp-handle-size) / 2); }
.drop-zone .top-9 { top: calc(var(--sp-handle-size) / -2); left: calc(90% - var(--sp-handle-size) / 2); }
.drop-zone .top-10 { top: calc(var(--sp-handle-size) / -2); left: calc(100% - var(--sp-handle-size) / 2); }

/* .attribute-row:hover {
    font-weight: 600;
} */

/* .modelling .entity-node.arrow-drag,
.modelling .entity-node.tool-drag:hover,
.modelling .observable-node.arrow-drag,
.modelling .observable-node.tool-drag:hover {
    min-height: 40px;
}

.projection .entity-node.arrow-drag .port-placement-projection,
.projection .observable-node.arrow-drag .port-placement-projection,
.modelling .entity-node.arrow-drag .port-placement-modelling,
.modelling .entity-node.tool-drag:hover .port-placement-modelling,
.modelling .observable-node.arrow-drag .port-placement-modelling,
.modelling .observable-node.tool-drag:hover .port-placement-modelling {
    display: flex;
}

.drop-area.leaf-drag {
    display: flex;
} */


/* 
    ==========
    HIDE / SHOW ACTIONS 
    ==========
*/
.diagram-point {
    display: none;
}

.link-selected .diagram-point {
    display: block;
}



/* 
    ==========
    STYLES 
    ==========
*/
.row-del {
    background: transparent;
    border: 0;
    font-weight: bold;
    opacity: .85;
    color: indianred;
    line-height: 12px;
    font-size: 12px;
}

.tooltip-small {
    font-size: 10px;
    padding: 5px;
}

.resize-top,
.resize-bottom {
    position: absolute;
    height: 3px;
    right: 0;
    left: 0;
    cursor: ns-resize;
}

.resize-right,
.resize-left {
    position: absolute;
    width: 3px;
    top: 0;
    bottom: 0;
    cursor: ew-resize;
}

.rotate-top,
.rotate-bottom {
    position: absolute;
    height: 6px;
    right: 0;
    left: 0;
    cursor: col-resize;
}

.rotate-right,
.rotate-left {
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    cursor: row-resize;
}

.resize-top { top: -3px; }
.resize-bottom { bottom: -3px; }
.resize-right { right: -3px; }
.resize-left { left: -3px; }
.rotate-top { top: -9px; }
.rotate-bottom { bottom: -9px; }
.rotate-right { right: -9px; }
.rotate-left { left: -9px; }

.resize-top-left,
.resize-top-right,
.resize-bottom-left,
.resize-bottom-right {
    position: absolute;
    width: 7px;
    height: 7px;
}

.resize-top-left {
    top: -3px;
    left: -3px;
    cursor: nwse-resize;
}

.resize-top-right {
    top: -3px;
    right: -3px;
    cursor: nesw-resize;
}

.resize-bottom-left {
    bottom: -3px;
    left: -3px;
    cursor: nesw-resize;
}

.resize-bottom-right {
    bottom: -3px;
    right: -3px;
    cursor: nwse-resize;
}

