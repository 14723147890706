.cadet-text-input,
.cadet-select,
.phenom-combo-box {
  border: none;
  background-color: transparent;
  color: #409b9e;
  text-shadow: 0px 0px 0px #409b93de;
  font-size: 80%;
  margin-bottom: 20px;
  padding: 7px 0px;
  width: 100%;
  font-weight: normal;
  box-shadow: 1px 1px 5px 1px #d2d2d2aa;
  margin-top: 7px;
  /* transition: box-shadow 300ms, margin 300ms; */
}

.cadet-text-input:hover:enabled,
.cadet-select:hover:enabled,
.cadet-text-input:focus:enabled,
.cadet-select:focus:enabled,
.phenom-combo-box:hover {
    margin-top: 6px;
    margin-bottom: 21px;
    margin-left: -1px;
    box-shadow: 2px 2px 7px 1px #d2d2d2;
}

.cadet-text-input.invalid {
    background-color: #fce9ff;
}

textarea.cadet-text-input {
  overflow-y: hidden;
  resize: vertical;
}

.cadet-text-input {
  padding-left: 5px;
}

.cadet-text-input:disabled,
.phenom-combo-box:disabled {
  color: #747575;
  text-shadow: 0px 0px 0px #747575de;
  box-shadow: none;
  cursor: default;
}

.cadet-select {
  width: fit-content;
  width: -moz-fit-content;
  padding-right: 20px;
  padding: 5px 0px;
  cursor: pointer;
}

.cadet-select option {
  cursor: pointer;
}

.cadet-select:disabled {
    box-shadow: none;
    color: rgb(116, 117, 117);
    -webkit-appearance: menulist-text;
    -moz-appearance: menulist-text;
    cursor: default;
    text-shadow: 0px 0px 0px #747575de;
}

.cadet-text-input:focus,
.cadet-select:focus,
.phenom-combo-box:focus {
  outline: none;
}

.cs-thin {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.cs-thin:hover:enabled,
.cs-thin:focus:enabled {
    margin-top: -1px;
    margin-bottom: 1px;
    margin-left: -1px;
    margin-right: 1px;
}

.cadet-text-input::placeholder {
  font-weight: normal;
  opacity: 1;
  color: #b6dddc;
  font-style: italic;
}

.cadet-fake-dropdown {
  list-style: none;
  padding: 0px;
  position: absolute;
  max-height: 450px;
  overflow-y: scroll;
  bottom: 30px;
  background-color: #fff;
  border: 2px solid #eaeaea;
  font-size: 85%;
  color: #409b9e;
  min-width: 600px;
}

.cadet-fake-dropdown li {
  cursor: pointer;
  padding: 5px 10px 5px 2px;
}

.cadet-fake-dropdown li:hover {
  background-color: #eaeaea;
}

/* a description-addendum class should be given to <p> elements underneath a cadet input or select field */
.description-addendum {
  margin-top: -10px;
  font-size: 80%;
  color: #565656;
  margin-bottom: 10px;
  padding: 5px 0px;
}

.extra-top-space {
  margin-top: 35px;
}

.toggle {
    height: 22px;
    width: 11%;
    margin: 0;
    padding: 0;
    border-radius: 11px;
    background-color: #dadada;
    position: relative;
    margin: 0 10px;
    border: 1px solid #409b9e;
    transition: all 100ms linear;
}

.toggle:hover {
    background-color: #e8e8e8;
}

.toggle > #first {
    height: 22px;
    width: 22px;
    display: inline-block;
    border-radius: 50%;
    background-color: #409b9e;
    position: absolute;
    transition: right 250ms linear, box-shadow 80ms linear;
    margin-top: -3px;
}

.toggle > #second {
    position: relative;
    padding: 0 15px 0 5px;
    color: #409b9e;
    width: calc(100% - 22px);
    transition: all 250ms linear;
    font-size: 85%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.tglLeft {
    right: calc(100% - 22px);
}

.tglRight {
    right: 0;
}

.toggle:hover > .tglLeft {
    box-shadow: 1px 0px 3px 0px #1c4749;
}

.toggle:hover > .tglRight {
    box-shadow: -1px 0px 3px 0px #1c4749;
}

.faux-autofill {
    position: absolute;
    margin: 0;
    z-index: 8;
    list-style: none;
    max-height: 380px;
    overflow-y: scroll;
    background-color: white;
    padding: 3px;
    overflow-x: hidden;
    box-shadow: 2px 2px 7px 1px #d2d2d2;
}

.faux-autofill > li {
    color: #409b9e;
    text-shadow: 0px 0px 0px #409b93de;
    padding: 5px;
    cursor: pointer;
}

.faux-autofill > li:hover {
    margin-top: -1px;
    margin-left: -1px;
    box-shadow: 1px 1px 5px 1px #d2d2d2aa;
}

.faux-clear {
    position: absolute;
    background: transparent;
    border: 0;
    font-weight: bold;
    opacity: .5;
    color: teal;
}

.faux-clear:hover {
    opacity: 1;
    background-color: #ffffff77;
}

.phenom-combo-box {
    padding: 0;
    height: 21px;
    margin: 0;
}

.phenom-combo-box:hover,
.phenom-combo-box:focus {
    margin: 0;
}

.phenom-combo-box .k-input {
    color: #409b9e;
    font-size: small;
    border: none;
    background-color: transparent;
    padding: 1.5px;
}
.phenom-combo-box .k-input:focus,
.phenom-combo-box .k-input:hover  {
    border: none;
    outline: none;
    background-color: transparent;
}

.phenom-combo-box .k-dropdown-wrap {
    background-color: transparent;
    outline: none;
    border: none;
}

.phenom-combo-box .k-select {
    display: none;
    /* background: none;
    color: #409b9e; */
}

.phenom-combo-box .k-i-close {
    right: 0;
}

/* .phenom-combo-box .k-select:hover {
    background: none;
    color: #4b3f94 !important;
}
.phenom-combo-box:hover .k-select {
    background: none;
    color: #409b9e;
} */

.k-item {
    white-space: nowrap !important;
    font-size: small;
}

.k-item:hover {
    color: #409b9e !important;
}

.lineLabel {
    display: flex;
    align-items: center;
    text-align: center;
    color: #565656;
    font-weight: bold;
    line-height: 0.1em;
    margin: 6px 0;
}

.lineLabel::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #eaeaea;
    margin-left: .5em;
}

.checkbox-group span:not(:last-child) {
    margin-right: 8px;
}

.checkbox-group label {
    font-weight: normal;
}



/* FOR DEMO */
.view-mod-textarea {
  height: 200px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 1.5;
  padding: 6px;
  border: 1px solid #eaeaea;
}

.view-mod-textarea p {
  margin: 0 0 0.25em;
}

.view-mod-textarea .error {
  color: red;
}

.view-mod-grid table {
  border-collapse: collapse;
  border-spacing: 2px;
}

.view-mod-grid tbody tr:hover {
  background-color: inherit;
}

.view-mod-grid tbody tr.selected {
  border-top: 1px solid #7500bb;
  border-bottom: 1px solid #7500bb;
}

.view-mod-grid tbody tr.matched {
  background-color: #ededed;
}

.view-mod-grid .view-mod-col-sm {
  width: 45px;
}


/* TEMPORARY FIX */
.view-mod-grid td {
  overflow: visible;
}
