.sub-menu-pages {
  --bg-primary: hsl(var(--skayl-violet-hs) 30%);
  --bg-primary-active: hsl(var(--skayl-purple-hs) 65%);
  --btn-disabled: gray;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  min-height: 40px;
  background-color: var(--bg-primary);
}

.sub-menu-actions {
  --bg-primary: hsl(var(--skayl-purple-hs) 65%);
  --bg-primary-active: hsl(var(--skayl-purple-hs) 58%);
  --btn-disabled: gray;
  display: flex;
  padding: 0 0.5em 0 0;
  margin: 0;
  background-color: var(--bg-primary);
}

.sub-menu-left,
.sub-menu-right {
  display: flex;
  height: 100%;
}

.sub-menu-left {
  justify-content: flex-start;
  flex: 5;
}

.sub-menu-right {
  justify-content: flex-end;
  flex: 2;
  gap: 15px;
}

.sub-menu-actions ul {
  gap: 0.5em;
}

[class^="sub-menu"].flex-end {
  justify-content: flex-end;
}

[class^="sub-menu"] ul {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  list-style: none;
}

[class^="sub-menu"] a,
[class^="sub-menu"] button {
  display: flex;
  color: #f2f2f2;
  font-size: 0.875em;
  padding: 0.65em 1em;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

[class^="sub-menu"] a:hover,
[class^="sub-menu"] button:hover {
  text-decoration: none;
  background-color: var(--bg-primary-active);
}

[class^="sub-menu"] a.disabled,
[class^="sub-menu"] button:disabled {
  color: darkgray;
  pointer-events: none;
}

[class^="sub-menu"] a.active {
  /* color: var(--bg-primary); */
  background-color: var(--bg-primary-active);
}

[class^="sub-menu"] button {
  padding: 0.35em;
  border: none;
  background-color: transparent;
}

[class^="sub-menu"] button.fas,
[class^="sub-menu"] button.far {
  font-size: 1.125em;
  color: inherit;
  background-color: transparent;
}

[class^="sub-menu"] button.fas:hover,
[class^="sub-menu"] button.far:hover {
  color: #f2f2f2;
}

[class^="sub-menu"] button:disabled {
  color: var(--btn-disabled);
}

.sub-menu-actions a {
  font-size: 0.875em;
  padding: .5em 1em;
}

/**
* KB BUTTON
*/
a.kb-link {
  padding: 0;
  width: 32px;
  height: 32px;
  color: inherit;
  margin-top: auto;
  margin-bottom: auto;
}

a.kb-link:hover {
  background-color: transparent;
}

a.kb-link span {
  font-size: 1.25em;
  width: 32px;
}

a.kb-link:hover span {
  color: #f2f2f2;
}

.manage-sub-menu {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  height: 100%;
  flex: 1;
  width: 100%;
}

.manage-sub-menu a{
  display: flex;
  color: #f2f2f2;
  font-size: 0.875em;
  padding: .5em 1em;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.manage-sub-menu a:disabled {
  pointer-events: none;
  color: darkgray;
}