/* bootstrap sets this value to 10px */
html {
  font-size: 16px;
}

body {
  font-family: var(--font-main);
  color: hsl(246, 24%, 32%);
  font-size: 16px;
  line-height:1.2;
  background-color: hsl(210, 5%, 91%);
  text-rendering: optimizeLegibility;
  overflow: hidden;
}

h1, h2, h3 {
  /* font-family: var(--font-header); */
  font-size: 18px;
  font-size: 1.125em;
  /* color:hsl(246, 24%, 32%); */
  text-transform: uppercase;
}



/*--------------------------------------------------------------
# APP
--------------------------------------------------------------*/

#phenom-app {
  display: grid;
  height: 100vh;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: 70px 1fr auto;
  grid-template-areas: 
    "header header header header"
    "modes tree content side"
    "footer footer footer footer";
  overflow: hidden;
}

#phenom-header {
  grid-area: header;
}

#phenom-modes {
  grid-area: modes;
}

#phenom-navtree {
  grid-area: tree;
  min-height: 0;
  width: 300px;
}

#phenom-content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  background: white;
  overflow: hidden;
}

.phenom-content-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.phenom-content-scrollable {
  position: relative;
  flex: 1;
  overflow: auto;
  scroll-behavior: smooth;
}

.details-accepted {
  box-shadow: inset 24px 0 2px -10px rgb(200, 255, 200);
}

.details-under-review {
  box-shadow: inset 24px 0 2px -10px rgb(255, 255, 200);
}

.details-rejected {
  box-shadow: inset 24px 0 2px -10px rgb(255, 200, 200);
}

#phenom-side {
  grid-area: side;
}

#phenom-footer {
  grid-area: footer;
  z-index: 0;
}


#phenom-content.login-page {
  background-color: #e8e9ea;
}

.resize-horizontal {
  display: flex;
  flex-direction: column;
  width: 3px;
  background-color: var(--resize-color, #ebecf0);
  cursor: ew-resize;
}

.resize-vertical {
  display: flex;
  height: 3px;
  background-color: var(--resize-color, #ebecf0);
  cursor: ns-resize;
}

.resize-horizontal:hover,
.resize-vertical:hover {
  background: #2684ff;
}


/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/
.header-container {
  display: flex;
  align-items: center;
  padding: 0.25em;
  background: white;
  height: 100%;
}

.header-logo {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  margin-left: 0.5em;
}

.header-logo > a {
  display: flex;
  gap: 5px;
  align-items: center;
}

.header-info {
  flex: 1;
  text-align: center;
}

.header-tools {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2em;
  padding: 0 1em;
}

.header-user {
  display: flex;
  gap: 0.5em;
}

.header-user .dropdown-menu span {
  display: block;
  padding: 3px 20px;
}

.header-user .dropdown-menu button {
  display: block;
  padding: 3px 20px;
  width: 100%;
  text-align: left;
  border: none;
  background: transparent;
}

.header-user .dropdown-menu button:hover {
  color: #262626;
  background-color: #f5f5f5;
}

#more-info-btn,
#more-settings-btn {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0;
  border: none;
  background-color: transparent;
}

#more-info-btn span:first-child {
  color: #333;
  font-size: 1.25em;
}

#more-settings-btn span:first-child {
  font-size: 10px;
  color: white;
  background: #404040;
  border: 1px solid white;
  outline: 1px solid #404040;
}


/*--------------------------------------------------------------
# LOGIN
--------------------------------------------------------------*/ 
.login-form {
  flex: 1;
  padding: 1.5em;
}



/*--------------------------------------------------------------
# FOOTER
--------------------------------------------------------------*/ 

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
}



/*--------------------------------------------------------------
# NOTIFICATIONS
--------------------------------------------------------------*/
.notifications-wrapper {
  visibility: visible;
  position: fixed;
  inset: 60px 60px auto auto;
  list-style: none;
  min-width: 500px;
  max-height: 70vh;
  transition: opacity .15s linear;
  z-index: 10002;
}

@media screen and (min-width: 1600px) {
  .notifications-wrapper {
    inset: 60px auto auto 1050px;
  }
}

.notifications-wrapper > .alert-item {
  position: relative;
  margin-bottom: 10px;
}

/* .notifications-wrapper > button { */
  /* background-color: transparent;
  border: 0px;
  position: absolute;
  top: 1px;
  right: 1px;
  font-weight: bold;
  color: #696969; */
/* } */

.notifications-wrapper > button:focus {
  outline: none;
}

.notifications-wrapper > li {
  padding: 5px 0px;
}

.notifications-wrapper > .error {
  color: #af1717;
}

.notifications-wrapper > .progress {
  color: #403873;
}

.notifications-wrapper > .info {
  color: #1fa150;
}

.notifications-wrapper > .warning {
  color: rgb(243, 122, 76);
}


/*--------------------------------------------------------------
# MISCELLANEOUS
--------------------------------------------------------------*/ 
.flex-end {
  justify-content: flex-end;
}

.flex-column {
  flex-direction: column;
}

#hoverTip {
  max-width: 300px;
}

.tool-tip {
  max-width: 300px;
}