/**
 * INDEX
 * ------------------------------------------------------------
 * # Node
 * ## Code Preview
 * # History
 * # Collapsable Layout
 * ------------------------------------------------------------
 */


#phenom-detail-container {
  display: flex;
  flex-direction: column;
  min-height: 0;
}


/*--------------------------------------------------------------
# Node
--------------------------------------------------------------*/

/* LAYOUT */
.edit-form-container {
  --gutter: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--gutter);
  padding: var(--gutter);
}

.edit-form {
  --gutter: 16px;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--gutter);
}

.edit-container {
  --gutter: 20px;
  position: relative;
}

.edit-top {
  grid-area: top;
}

.edit-side {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0 1 300px;
}

.edit-middle {
  grid-area: middle;
}

.edit-bottom {
  grid-area: bottom;
}

.edit-deprecated {
  background-color: #f6f578;
  /* border: 3px solid red; */
  padding: 10px;
  width: 100%;
  font-weight: 900;
  box-shadow: 0px 0px 5px;
}

.edit-tag-manager {
  grid-area: tags;
}

.edit-top > .deprecated-banner {
  margin: 0;
}

.phenom-form-row > div {
  flex: 1 1 200px;
}

/* Node Detail Box */
.node-box-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 1em;
}

.node-box {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  position: relative;
  padding: 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 2px solid hsl(var(--skayl-violet-hs) 30%);
}

.node-box i {
  font-size: 13px;
}

.node-box p {
  font-size: 14px;
  margin: 0;
}

.node-box .node-box-btns {
  position: absolute;
  top: 2px;
  right: 5px;
}

/*--------------------------------------------------------------
## Code Preview
--------------------------------------------------------------*/
.code-preview {
  --border-color: rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.code-preview-header {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.code-preview-header li {
  padding: 0.25em;
  max-width: 100px;
  cursor: pointer;
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-top-right-radius: 10px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f2f2f2;
}

.code-preview-header li.active,
.code-preview-header li:hover {
  background-color: #fff;
}

.code-preview-header li:last-child {
  border-right: 1px solid var(--border-color);
}

.code-preview-body {
  height: 200px;
  padding: 0.5em;
  border: 1px solid var(--border-color);
  overflow: auto;
  white-space: pre-wrap;
}

.code-preview-content {
  display: none;
  gap: 0.5em;
  flex-wrap: wrap;
}

.code-preview-content.active {
  display: flex;
}

.code-preview-body p {
  flex: 1 1 300px;
}


/*--------------------------------------------------------------
# History
--------------------------------------------------------------*/
.node-history-wrapper {
  min-height: 68px;
}

.node-history-wrapper > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.node-history-wrapper .cs-thin:disabled {
  color: #989898;
}




/*--------------------------------------------------------------
# Collapsable Layout
--------------------------------------------------------------*/
.edit-collapsable {
  border: 1px solid #e0e0e0;
}

.edit-collapsable > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  background-color: lightblue;
  transition: filter 1s ease;
}

.edit-collapsable > header:hover {
  filter: brightness(105%);
  cursor: pointer;
}

.edit-collapsable > header > div {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.edit-collapsable > header > div:first-child {
  flex: 1;
}

.edit-collapsable .edit-side,
.edit-collapsable .collapsed {
  display: none;
}

.edit-form-collapsable {
  overflow: hidden;
  transition: height 200ms ease-out;  
}

/* need to give width on manage page*/
.manage-collasable > .edit-collapsable{
  width: 100%
}


.placeholder-square {
  height: 200px;
  width: 200px;
}

.placeholder-input-field {
  height: 30px;
}

.placeholder-content {
  overflow: hidden;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #ededed;
}

@keyframes placeholderAnimate {
  0% { 
    opacity: 30%; 
  }
  50% {
    opacity: 100%;
  }
  100% { 
    opacity: 30%; 
  }
}