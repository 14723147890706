/* 
  Overrides for Kendo React.
    Documentation shows a functionality but for whatever reason, it does not work for us. 
    This is a workaround to force it to work.
*/


/* Checkbox's indeterminate state */
.k-state-indeterminate ~ label:after {
  content: "\e121";
  transform: scale(1);
}

/* Overriding alternating row background for specific grid */
.blank-grid tr.non-destructure-row {
  background-color: #f2f2f2;
}

.blank-grid tr.blank-row {
  background-color: white;
}

.blank-grid.k-grid tr:hover {
    background-color: #f8f8f8;
}

.k-checkbox-label {
  font-weight: 400;
  color: #413D64;
  text-align: left;
  font-size: 1em;
  text-rendering: optimizeLegibility;
}


.k-column-resizer {
  width: .5em;
  height: 1000%;
  display: block;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  cursor: col-resize;
}