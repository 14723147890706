div.circos-tooltip {
  inset: 0 auto auto 0;
}

.meridian-map-details {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0.5em;
}

.meridian-map-details td {
  overflow: auto;
}