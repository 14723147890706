/**
 * INDEX
 * ------------------------------------------------------------
 * # Inputs
 * # Labels
 * # Border Effects
 * # Error Messages
 * ------------------------------------------------------------
 */



/*--------------------------------------------------------------
# Shadows
--------------------------------------------------------------*/
.without-box-shadow .cadet-input-wrapper,
.without-box-shadow .cadet-textarea-wrapper {
  box-shadow: none !important;
}


/*--------------------------------------------------------------
# Labels
--------------------------------------------------------------*/
.cadet-label,
.cadet-line-label {
  font-size: 1rem;
  color: var(--label-color);
}

.cadet-line-label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 0 5px;
}

.cadet-line-label > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cadet-line-label:after {
  display: block;
  content: "";
  flex: 1;
  height: 2px;
  background: var(--label-line-color);
}

.cadet-line {
  display: block;
  width: 100%;
  min-height: 3px;
  background: var(--label-line-color);
  margin: 0;
}


/*--------------------------------------------------------------
#. Inputs
--------------------------------------------------------------*/
.cadet-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.cadet-input-wrapper {
  display: flex;
  background-color: white;
  box-shadow: 1px 1px 5px 1px hsl(0 0% 82%);
}

.cadet-textarea-wrapper {
  display: flex;
  position: relative;
  background-color: white;
  box-shadow: 1px 1px 5px 1px hsl(0 0% 82%);
}

.cadet-textarea-wrapper .floating-buttons {
  display: flex;
  position: absolute;
  right: 0;
}



.cadet-select-single,
.cadet-input,
.cadet-textarea {
  --h: 182;
  --s: 42%;
  --l: 44%;
  display: block;
  position: relative;
  font-size: 13px;
  padding: 7px 5px;
  flex: 1;
  width: 100%;
  min-height: 30px;
  color: hsl(var(--h), var(--s), var(--l));
  text-shadow: 0px 0px 0px hsla(var(--h), var(--s), var(--l), 0.871);
  border: none;
  outline: none;
  background: white;
  overflow: hidden;
}

.cadet-select-single:disabled,
.cadet-input:disabled,
.cadet-textarea:disabled {
  --h: 180;
  --s: 0%;
  --l: 46%;
  box-shadow: none;
  cursor: default;
  background: #fafafa;
}

.cadet-textarea {
  min-height: 100px;
  resize: vertical;
}

.cadet-select-single {
  padding: 5px 0px;
  cursor: pointer;
}

.short-cadet-select .cadet-select-single{
  min-height: 15px;
  padding: 0;
}


.cadet-radio-horizontal {
  display: flex;
  position: relative;
  font-weight: 600;
  color: hsl(0, 0%, 95%);
  /* background: hsl(210 38% 95%); */
  background: hsl(var(--skayl-zero-hs) 60%);
}

.cadet-radio-horizontal label {
  display: block;
  flex: 1;
  margin: 0;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid white;
  z-index: 1;
}

.cadet-radio-horizontal .slider {
  position: absolute;
  top: 0;
  left: 0;
  background: hsl(var(--skayl-teal-hs) 50%);
  transition: left 200ms linear;
  z-index: 0;
}

.cadet-combobox {
  display: flex;
}

.cadet-dropdown-list {
  position: relative;
  background: #fff;
  box-shadow: 0 1px 5px 1px #d2d2d2aa;
}

.cadet-dropdown-list .no-data {
  font-size: 14px;
  margin: auto;
  padding: 10px;
  border: 1px solid #d2d2d2;
}

.cadet-dropdown-list ul {
  font-size: 14px;
  padding: 0 !important;
  margin: 0;
  max-height: 200px;
  min-width: 100%;
  border: 1px solid #d2d2d2;
  list-style: none;
  overflow: auto;
}

.cadet-dropdown-list li {
  padding: 5px 10px;
  min-width: 100%;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}

.cadet-dropdown-list li:focus {
  border: 1px solid var(--skayl-teal);
  box-shadow: inset 0 0 3px var(--skayl-teal);
}

.cadet-dropdown-list li:hover,
.cadet-dropdown-list li.active {
  background-color: #f5f5f5;
}

.cadet-dropdown-list li.active {
  border: 1px dotted rgb(234, 0, 255);
}

.cadet-dropdown-list li.disabled {
  background: hsl(var(--skayl-zero-hs) 85%);
}












.cadet-toggle {
  --toggle-height: 30px;
  --toggle-width: 100px;
  --handle-size: calc(var(--toggle-height) - 2px);
  --toggle-hs: var(--skayl-teal-hs);

	display: block;
  position: relative;
	width: var(--toggle-width);
	height: var(--toggle-height);
	padding: 3px;
  margin: 0;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	box-sizing: content-box;
}

.cadet-toggle-input {
  display: none;
}

.cadet-toggle-label {
	display: block;
  position: relative;
	height: inherit;
	font-size: 11px;
  font-weight: 400;
  background: hsl(var(--toggle-hs) 80%);
	border-radius: inherit;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2), inset 0 0 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 300ms ease;
}

.cadet-toggle.disabled {
  --toggle-hs: var(--skayl-zero-hs) !important;
}

.cadet-toggle-label:before, 
.cadet-toggle-label:after {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  transition: inherit;
}

.cadet-toggle-label:before {
  justify-content: flex-end;
	content: attr(data-off);
	padding-right: 10px;
  left: 0;
	color: #555;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.cadet-toggle-input:checked ~ .cadet-toggle-label:before {
	left: 100%;
}

.cadet-toggle-label:after {
	content: attr(data-on);
  left: -100%;
	padding-left: 10px;
	color: #f0f0f0;
  background: hsl(var(--toggle-hs) 40%);
}

.cadet-toggle-input:checked ~ .cadet-toggle-label:after {
  left: 0;
}

.cadet-toggle-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: var(--handle-size);
	height: var(--handle-size);
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: all 300ms ease;
}

.cadet-toggle-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: calc(var(--handle-size) / 3);
	height: calc(var(--handle-size) / 3);
  transform: translate(-50%, -50%);
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.cadet-toggle input:checked ~ .cadet-toggle-handle {
	left: calc(100% - var(--handle-size) - 4px);
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.cadet-node-link {
  display: block;
  align-self: center;
  margin-right: 6px;
}


/*--------------------------------------------------------------
# Border Effects
--------------------------------------------------------------*/

.border-effect-01 {
  --border-size: 1px;
  --border-transition: 0.75s;
  --color-error: hsl(var(--bs-danger-hs) 50%);
  /* --color-focus: hsl(var(--skayl-teal-hs) 69%); */
  --color-focus: hsl(var(--skayl-zero-hs) 87%);
  position: relative;
}

[class*="border-effect"] .be-top:before {
  width: 0;
  height: var(--border-size);
  top: 0;
  left: 0;
}

[class*="border-effect"] .be-bottom:after {
  width: 0;
  height: var(--border-size);
  bottom: 0;
  right: 0;
}

[class*="border-effect"] .be-right:before {
  width: var(--border-size);
  height: 0;
  bottom: 0;
  right: 0;
}

[class*="border-effect"] .be-left:after {
  width: var(--border-size);
  height: 0;
  top: 0;
  left: 0;
}


/* [class*="border-effect"]:focus .be-top:before, 
[class*="border-effect"]:focus .be-bottom:after, */
[class*="border-effect"] *:focus ~ .be-top:before, 
[class*="border-effect"] *:focus ~ .be-bottom:after,
[class*="border-effect"].error .be-top:before, 
[class*="border-effect"].error .be-bottom:after {
  width: 100%;
}

/* [class*="border-effect"]:focus .be-right:before, 
[class*="border-effect"]:focus .be-left:after, */
[class*="border-effect"] *:focus ~ .be-right:before, 
[class*="border-effect"] *:focus ~ .be-left:after,
[class*="border-effect"].error .be-right:before, 
[class*="border-effect"].error .be-left:after {
  height: 100%;
}


/* span[hello="Cleveland"][goodbye="Columbus"] */

/* border color */
[class*="border-effect"] .be-top:before, 
[class*="border-effect"] .be-bottom:after,
[class*="border-effect"] .be-right:before, 
[class*="border-effect"] .be-left:after {
  content: "";
  position: absolute;
  transition: var(--border-transition);
  background-color: var(--color-focus);
}

[class*="border-effect"].error .be-top:before,
[class*="border-effect"].error .be-bottom:after,
[class*="border-effect"].error .be-right:before,
[class*="border-effect"].error .be-left:after {
  background-color: var(--color-error);
}

[class*="border-effect"].error .k-i-warning {
  color: var(--color-error);
}