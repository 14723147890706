/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
.manage-detail-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

#manage-files {
  display: flex;
  padding: 1em 0em 1em 1em;
  width: 40%;
}

#manage-single-file {
  display: flex;
  flex-direction: column;
  padding: 0em 1em 0em 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  flex: 1;
  overflow: auto;
}

.manage-detail-container h2 {
  font-weight: 900;
  text-transform: inherit;
  margin: 0;
}

.manage-files-wrapper {
  display: grid;
  grid-template-rows: 1fr 13px 1fr;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  overflow: hidden;
}



/*--------------------------------------------------------------
# Tree Header
--------------------------------------------------------------*/
.manage-detail-container .tree-content .tree-search {
  flex: inherit;
  padding: 0.25em;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
}

.model-tree-sort-btns{
  border-left: 2.5px solid #f0f0f0;
  float: right;
}

.model-tree-sort-btns button.sort-button{
  background: transparent;
  font-size: 20px;
  border: none;
  margin-left: 3px;
}
/* second fa icon is slightly smaller */
.model-tree-sort-btns button.sort-button2{
  background: transparent;
  font-size: 21.5px;
  border: none;
}

.model-tree-sort-btns button.sort-button-activated{
  font-size: 20px;
  margin-left: 3px;
  border: none;
  border-radius: 5.5px;
  background: hsl(var(--skayl-purple-hs) 75%);
}

.model-tree-sort-btns button.sort-button2-activated{
  font-size: 21.5px;
  border: none;
  border-radius: 5.5px;
  background: hsl(var(--skayl-purple-hs) 75%)
}



/*--------------------------------------------------------------
# Model Leaves / Family section
--------------------------------------------------------------*/
.project-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 0.9em;
  height: 100%;
  gap: 2em;
}

.project-detail .model-leaves-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-bottom: 0.5em;
  max-height: 160px;
  overflow: auto;
}

.project-detail .model-leaves label {
  margin: 0;
}

.project-detail .model-leaf {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.project-detail .model-leaf:hover {
  background-color: inherit;
}


/*--------------------------------------------------------------
# New Model Selection - when creating a new project
--------------------------------------------------------------*/
.project-detail .new-model-selection {
  display: flex; 
  gap: 1em; 
  overflow: hidden;
}

.project-detail .new-model-selection > div {
  flex: 1;
  display: flex;
  width: 50%;
  flex-direction: column; 
}
