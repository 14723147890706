.dashboard-notes {
  --header-color: #bdc3c7;
}

.dashboard-notes h2,
.accordionItem.active .accordionItemHeading {
  margin: 0;
  padding: 0.625em;
  color: white;
  background-color: var(--header-color);
}

.dashboard-notes h3 {
  margin: 0;
}

.dashboard-notes section {
  /* margin-bottom: 1em; */
}

/*--------------------------------------------------------------
# DISTRO
--------------------------------------------------------------*/
.distro {
  position: sticky;
  top: 0;
}
.distro p {
  padding: 2em;
  margin: 0;
  border: 1px solid var(--header-color);
}

/*--------------------------------------------------------------
# MODEL STATS
--------------------------------------------------------------*/
.model-stats-content {
  padding: 2em;
  margin-bottom: 1em;
  border: 1px solid var(--header-color);
}

.model-stats-content .cadet-table {
  border-spacing: 1.25em 0;
  margin: 0 auto;
}


/*--------------------------------------------------------------
# RELEASE NOTES
--------------------------------------------------------------*/
.accordionItem{
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Open-sans',Arial,sans-serif;
}

.accordionItemHeading{
  cursor: pointer;
  margin: 0px 0px 10px 0px;
  padding: 10px;
  background:#34495E;
  color:#fff;
  width: 100%;
}

/* .accordionItem.close{
  filter: alpha(opacity=80);
  opacity: .8;
}

.accordionItem.close:hover{
  filter: alpha(opacity=100);
  opacity: 1;
  transition: opacity .25s ease-in-out;
} */

.accordionItemContent{
  height: 0px;
  transform: scaleY(0);
  display:block;
}
.active .accordionItemContent{
  height: 100%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 100%;
  margin: 0px 0px 10px 0px;
  display:block;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.4s ease;
  box-sizing: border-box;
}

/* .open .accordionItemHeading{
  margin:0px;
  background-color: #bdc3c7;
  color: #222;
} */