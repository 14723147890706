/**
 * INDEX
 * ------------------------------------------------------------
 * # Grid
 * # Modal Node Builder
 * ------------------------------------------------------------
 */


.fill-vertical {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  min-height: 0;
}

.overflow-hidden {
  overflow: hidden;
}

/*--------------------------------------------------------------
# Grid
--------------------------------------------------------------*/
.flex-container {
  --gutter: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--gutter);
}

.p-row {
  --columns: 12;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: var(--gutter, 16px);
}

.p-no-gutter {
  --gutter: 0;
}

.p-with-flex > * {
  flex: 1;
}

.p-no-flex > * {
  flex: none;
}

.p-align-center {
  align-items: center;
}

.row-no-gutter-row {
  row-gap: 0;
}

.row-no-gutter-column {
  column-gap: 0;
}

/* 
  Calculation:
    column-size: num / columns * 100%
    gutter-size: (columns - num) / columns * gutter
*/
.p-col {
  --col-size:    calc(var(--num) / var(--columns) * 100%);
  --gutter-size: calc((var(--columns) - var(--num)) / var(--columns) * var(--gutter, 16px));
  display: flex;
  flex-direction: column;
  flex-basis:    calc(var(--col-size) - var(--gutter-size));
  flex-grow: 1;
  flex-shrink: 1;
  gap: var(--gutter, 16px);
}

.p-col-fixed { flex-grow: 0; }
.p-col-1 { --num: 1; }
.p-col-2 { --num: 2; }
.p-col-3 { --num: 3; }
.p-col-4 { --num: 4; }
.p-col-5 { --num: 5; }
.p-col-6 { --num: 6; }
.p-col-7 { --num: 7; }
.p-col-8 { --num: 8; }
.p-col-9 { --num: 9; }
.p-col-10 { --num: 10; }
.p-col-11 { --num: 11; }
.p-col-12 { --num: 12; }

.grid {
  --row-size: 200px;
  --col-counts: 2;
  display: grid;
  grid-template-columns: repeat(var(--col-counts), auto);
  gap: var(--gutter, 16px);
}




.standard-container { 
  container-name: standard-container;
  container-type: inline-size;
}


@container standard-container (width < 768px) { 
  .p-col-6 { 
    --num: 12; 
  }
}


/*--------------------------------------------------------------
# Phenom Tree
--------------------------------------------------------------*/
.phenom-tree {
  display: flex;
  padding: 0;
  overflow: auto;
}

.phenom-tree > ul {
  padding-left: 0;
}

.phenom-tree ul ul {
  padding-left: 2.5em;
  list-style: none;
}

.phenom-tree li {
  flex: 1;
}

.phenom-tree .leaf-node {
  display: flex;
  align-items: center;
  padding: 0.35em;
  white-space: nowrap;
}

.phenom-tree .leaf-node:hover {
  background-color: rgb(231, 244, 249);
}

.phenom-tree .leaf-node.selected {
  background-color: #b3e8ff;
}

.phenom-tree .leaf-node > * {
  display: block;
  cursor: pointer;
}

.phenom-tree .leaf-node .leaf-node-text {
  flex: 1;
}






/*--------------------------------------------------------------
# Modal Node Builder
--------------------------------------------------------------*/
.modal-container {
  position: fixed;
  inset: 0;
  z-index: 12;
}

.modal-bg {
  width: 100%;
  height: 100%;
  background: hsl(0 0% 0% / 50%);
  /* backdrop-filter: blur(3px); */
}

.modal-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 1000px;
  height: 100%;
  right: 0;
  background: white;
  z-index: 1;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 2px 10px;
  background-color: hsl(var(--skayl-zero-hs) 96%);
  box-shadow: inset 0 -5px 10px -10px #000000;
}
