:root {
  /* hsl(190, 75%, 50%) */
  --skayl-blue-hs: 190 75%;
  --skayl-blue: hsl(var(--skayl-blue-hs) 50%);

  /* hsl(170, 45%, 69%) */
  --skayl-teal-hs: 170 45%;
  --skayl-teal: hsl(var(--skayl-teal-hs) 69%);

  /* hsl(110, 51%, 85%) */
  --skayl-mint-hs: 110 51%;
  --skayl-mint: hsl(var(--skayl-mint-hs) 85%);

  /* hsl(65, 100%, 42%) */
  --skayl-green-hs: 65 100%;
  --skayl-green: hsl(var(--skayl-green-hs) 42%);

  /* hsl(53, 71%, 75%) */
  --skayl-yellow-hs: 53 71%;
  --skayl-yellow: hsl(var(--skayl-yellow-hs) 75%);

  /* hsl(41, 90%, 60%) */
  --skayl-orange-hs: 41 90%;
  --skayl-orange: hsl(var(--skayl-orange-hs) 60%);

  /* hsl(326, 66%, 35%) */
  --skayl-berry-hs: 326 66%;
  --skayl-berry: hsl(var(--skayl-berry-hs) 35%);

  /* hsl(251, 24%, 30%) */
  --skayl-violet-hs: 251 24%;
  --skayl-violet: hsl(var(--skayl-violet-hs) 30%);

  /* hsl(298, 13%, 62%) */
  --skayl-purple-hs: 298 13%;
  --skayl-purple: hsl(var(--skayl-purple-hs) 62%);

  /* hsl(204, 61%, 86%) */
  --skayl-sky-hs: 204 61%;
  --skayl-sky: hsl(var(--skayl-sky-hs) 86%);

  /* hsl(0, 0%, 0%) */
  --skayl-zero-hs: 0 0%;
  --skayl-grey: hsl(var(--skayl-zero-hs) 65%);        /* hsl(0, 0%, 65%) */
  --skayl-darkgrey: hsl(var(--skayl-zero-hs) 38%);    /* hsl(0, 0%, 38%) */
  --skayl-black: hsl(var(--skayl-zero-hs) 20%);       /* hsl(0, 0%, 20%) */

  /* hsl(300, 38%, 37%) */
  --sp-purple-hs: 300 38%;
  --sp-purple: hsl(var(--sp-purple-hs) 37%);

  /* hsl(222, 44%, 49%) */
  --sp-blue-hs: 222 44%;
  --sp-blue: hsl(var(--sp-blue-hs) 49%);

  /* hsl(163, 33%, 59%) */
  --sp-teal-hs: 163 33%;
  --sp-teal: hsl(var(--sp-teal-hs) 59%);

  /* hsl(7, 84%, 62%) */
  --sp-orange-hs: 7 84%;
  --sp-orange: hsl(var(--sp-orange-hs) 62%);

  /* hsl(225, 2%, 49%) */
  --sp-gray-hs: 225 2%;
  --sp-grey: hsl(var(--sp-gray-hs) 49%);

  /* hsl(0, 11%, 16%) */
  --sp-black-hs: 0 11%;
  --sp-black: hsl(var(--sp-black-hs) 16%);

  /* --- BOOTSTRAP COLORS --- */

  /* hsl(211, 100%, 90%) */
  --bs-primary-hs: 211 100%;   
  --bs-primary: hsl(var(--bs-primary-hs) 90%);

  /* hsl(220, 5%, 89%) */
  --bs-secondary-hs: 220 5%;     
  --bs-secondary: hsl(var(--bs-secondary-hs) 89%);

  /* hsl(134, 41%, 88%) */
  --bs-success-hs: 134 41%;    
  --bs-success: hsl(var(--bs-success-hs) 88%);

  /* hsl(134, 41%, 91%) */
  --bs-danger-hs: 355 70%;    
  --bs-danger: hsl(var(--bs-danger-hs) 91%);

  /* hsl(46, 100%, 90%) */
  --bs-warning-hs: 47 100%;    
  --bs-warning: hsl(var(--bs-warning-hs) 90%);

  /* hsl(46, 100%, 88%) */
  --bs-info-hs: 189 53%;    
  --bs-info: hsl(var(--bs-info-hs) 88%);

  /* hsl(200, 4%, 85%) */
  --bs-dark-hs:       200 4%;    
  --bs-dark: hsl(var(--bs-dark-hs) 85%);
  /* --bs-light-hs:      0 0%;       hsl(0, 0%, 100%) */

  /* Old version of scratchpad */
  --item-select: var(--skayl-mint);
  --item-prevent: rgba(0,0,0,0.3);
  
  --color-sp-row: hsl(var(--skayl-zero-hs) 93%);
  --color-ae: hsl(209, 100%, 91%);
  --color-specializes: hsl(56, 89%, 90%);
  --color-sidebar: hsl(220, 46%, 95%);

  --label-color: #565656;
  --label-line-color: #eaeaea;
}

.bs-text-warning {
  color: hsl(var(--bs-warning-hs) 50%);
}
