#yii-debug-toolbar {
  position: fixed;
  inset: auto 0 0 auto;
  padding: 5px;
  border-top-left-radius: 5px;
  border: 1px solid darkgray;
  border-bottom: none;
  border-right: none;
  cursor: pointer;
  z-index: 99999;
}



.btn-main {
  background-color: rgb(138, 163, 178);
  color: #fff;
  border: none;
  padding: 0.35em 0.5em;
}










.btn-link {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 100%;
    font-weight: normal;
    color: #777777;
    border-radius: 0;
	transition: .3s;
}
.btn-link:hover,
.btn-link:focus {
  color: #AEC800;
  text-decoration: none;
  background-color: transparent;
}


.btn-primary,
.btn-primary:focus {
  color:#fff;
  background-color:#747575;
  font-size: 80%;
  text-transform:uppercase;
  font-family:futura-pt, sans-serif;
  transition: .3s;
  border-radius:0px;
  border-color: transparent;
}


.btn-primary:active,
.btn-primary:hover,
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus
{
  color: #fff;
  background-color: #ccc;
  border-color: transparent;
}


.hover-menu {
  position: absolute;
  background-color: white;
  display: flex;
  z-index: 100;
  box-shadow: 2px 8px 18px 2px rgba(0,0,0,0.2);
  flex-direction: column;
}

.hover-menu-btn {
  z-index: inherit;
  padding: 3px 2px;
  width: 175px;
  display: flex;
  width: "100%";
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hover-menu-btn:not(:last-child) {
  border-bottom: 2px solid gainsboro;
}

.hover-menu-btn:hover {
  background-color: hsl(var(--skayl-violet-hs) 30%);
  color: white;
}

.hover-menu-btn:hover .k-checkbox-label {
  color: white !important;
}

.hover-menu-btn p{
  text-align: center;
  margin: 0;
  padding: 0;
}

.hover-menu.review-menu1 {
  transform: translateY(-105%);
}

.hover-menu.review-menu2 {
  transform: translateY(-97%);
}

/*--------------------------------------------------------------
# CSS no longer in use
--------------------------------------------------------------*/
.btn-secondary,
.btn-secondary:focus {
  color:#323232;
  background-color:#fff;
  font-size: 80%;
  border-color:#c2c3c4;
  border-width:1px;
  border-radius:0px;
  text-transform:uppercase;
  font-family:futura-pt, sans-serif;
  transition: .3s;
  float: right;
	width: fit-content;
	width: -moz-fit-content;
}

.btn-secondary:active,
.btn-secondary:active:hover,
.btn-secondary.active:hover,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.open > .dropdown-toggle.btn-secondary:focus,
.btn-secondary:active.focus,
.btn-secondary.active.focus,
.open > .dropdown-toggle.btn-secondary.focus
{
  color: #fff;
  background-color: #ccc;
  border-color: transparent;
}


.btn-tertiary,
.btn-tertiary:focus {
	width: fit-content;
	width: -moz-fit-content;
  color:#323232;
  background-color:#fff;
  font-size: 80%;
  border-color:#c2c3c4;
  border-width:1px;
  border-radius:0px;
  text-transform:uppercase;
  font-family:futura-pt, sans-serif;
  transition: .3s;
  float: left;
  font-size: 11px;
  padding: 3px 6px;
}

.btn-tertiary:active,
.btn-tertiary:hover,
.btn-tertiary:active:hover,
.btn-tertiary.active:hover,
.open > .dropdown-toggle.btn-tertiary:hover,
.btn-tertiary:active:focus,
.btn-tertiary.active:focus,
.open > .dropdown-toggle.btn-tertiary:focus,
.btn-tertiary:active.focus,
.btn-tertiary.active.focus,
.open > .dropdown-toggle.btn-tertiary.focus
{
  color: #fff;
  background-color: #ccc;
  border-color: transparent;
}


.btn-subnav,
.btn-subnav:focus {
	float: left;
	display: inline-block;
	color:#fff;
	width: 100%;
  background-color:#c2c3c4;
  font-size: 80%;
  font-family:futura-pt, sans-serif;
  transition: .3s;
  border-radius:0px;
  border-color: transparent;
  text-align: left;
}

.btn-subnav:active,
.btn-subnav:active:hover,
.btn-subnav.active:hover,
.open > .dropdown-toggle.btn-subnavhover,
.btn-subnav:active:focus,
.btn-subnav.active:focus,
.open > .dropdown-toggle.btn-subnav:focus,
.btn-subnav:active.focus,
.btn-subnav.active.focus,
.open > .dropdown-toggle.btn-subnav.focus
{
  color: #fff;
  background-color: #747575;
  border-color: transparent;
}

.btn-subnav:hover {
  color: #fff;
  background-color:#747575;
  border-color: transparent;
}

.subnav-placeholder{
	color: #ccc;
	background-color: transparent;
	text-align: left;
	display: inline-block;
	width: 100%;
	font-size: 80%;
	padding: 12px;
    font-family:futura-pt, sans-serif;
	cursor: pointer;
}


#edit-buttons-bar .btn-secondary {
	margin-right: 10px;
}

.btn-secondary:hover {
  color: #fff;
  background-color:#ccc;
  border-color: #ccc;
}
.btn-map {
	margin: 0px;
	width:100%;
	float:left;
}
.btn-block {
  display: block;
  width: 100%;
  border-bottom: 0px;
}
.btn-block38 {
  display: block;
  width: 100%;
  border-bottom: 0px;
  height: 38px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.health-check-btn {
  background-color: hsl(var(--skayl-violet-hs) 30%);
  color: #fff;
  border: none;
}

.health-check-btn:hover:not([disabled]) {
  background-color: hsl(var(--skayl-purple-hs) 65%);
}

.health-check-btn:active:not([disabled]) {
  background-color: hsl(var(--skayl-purple-hs) 60%);
}