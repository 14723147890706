.subview-wrapper {
  padding: 10px 5px 10px;
  margin: 10px 15px 15px;
  position: relative;
  background-color: #fff;
}

.deprecated {
  /* filter:brightness(85%); */
}

.tree-height {
    min-height: 500px;
}

.tree-height-w-buttons {
    min-height: 455px;
}

.sub-subview-wrapper {
  padding: 20px;
  border: 1px solid #dedede;
  margin-bottom: 25px;
}

.edit-side-bar {
    display: flex;
    flex-direction: column;
    width: 23%;
    padding-left: 30px;
}

.notification-bar {
  padding: 10px;
  font-size: 90%;
  color: #989898;
}

.notification-bar > .cadet-anchor {
  font-size: 100%;
}

.node-history-wrapper > span > p {
  font-size: 90%;
  color: #989898;
}

.detail-box {
  border: 1px solid #c2c3c4;
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.detail-box > span {
  background-color: #c2c3c4;
  font-size: 95%;
  font-weight: bold;
  color: #313131;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-box > .cadet-select {
  margin-top: 5px;
  margin-left: 3px;
  margin-bottom: 5px;
  width: calc(100% - 6px)
}

.detail-box > .cadet-select:hover:enabled, .detail-box > .cadet-select:focus:enabled {
  margin-left: 2px;
  margin-top: 4px;
  margin-bottom: 6px;
}

.detail-box > textarea {
  background-color: transparent;
  border: none;
  font-size: 80%;
  color: #565656;
  overflow-y: hidden;
  max-width: 100%;
  flex-grow: 1;
  padding-bottom: 20px;
}

.detail-box > .cadet-text-input {
  padding-left: 6px;
  margin-bottom: 0;
}

.detail-box > .input-table,
.detail-box > textarea,
.detail-box > span,
.detail-box > .form-button {
  padding-left: 4px;
}

.top-button-wrapper {
  position: absolute;
  right: 0px;
  top: -76px;
  padding: 0px 10px;
}

.top-button-wrapper button.bordered-button {
    height: fit-content;
}

.top-buttons-list {
  position: absolute;
  list-style: none;
  padding: 0px;
  margin-left: 10px;
  background-color: #e8e9ea;
  border: 2px solid #c2c3c4;
  width: 140px;
  top: 34px;
  font-size: 95%;
  font-weight: bold;
  z-index: 100;
}

.top-buttons-list > li {
  padding: 5px 10px;
  cursor: pointer;
  color: #a5a5a5;
}

.top-buttons-list > li:hover {
  background-color: whitesmoke;
}

.top-buttons-list > li.sub-menu {
    height: 28px;
}

.top-buttons-list > li.sub-menu ul {
    display: none;
}

.top-buttons-list > li.sub-menu:hover ul {
    display: block;
    position: relative;
    list-style: none;
    padding: 0;
    right: 150px;
    top: -25px
}

.system-axis-wrapper {
  border: 2px solid #eaeaea;
  padding: 5px 15px 0px 5px;
}

.system-axis-wrapper > label:first-child{
  color: #565656;
  margin-bottom: 10px;
  font-size: 95%;
}

.system-axis-wrapper .form-label {
  font-size: 90%;
}

.dialog-box {
  position: fixed;
  border: 2px solid #565656;
  min-width: 40%;
  width: fit-content;
  width: -moz-fit-content;
  z-index: 0.5;
  color: #565656;
  background-color: #fff;
  padding-bottom: 20px;
  color: #989898;
  font-size: 90%;
}

.dialog-box > span:first-child {
  display: block;
  background-color: #565656;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  color: #fff;
}

.dialog-box .cadet-anchor, .dialog-box .cadet-select {
  font-size: 100%;
}

.dialog-box > span:first-child > button {
  background-color: transparent;
  border: none;
}

.dialog-box > span:first-child > button:focus {
  outline: none;
}

.dialog-box > span:nth-child(2) {
  margin: 0px 20px;
}

.impact-header {
  color: white;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.tab-nav {
  display: flex;
  background-color: #ccc;
  color: white;
  text-transform: uppercase;
}

.tab-nav > a {
  font-size: 90%;
  padding: 6px 30px;
  padding-top: 7px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.tab-nav > a:hover, .tab-nav-active {
  background-color: #dbdbdb;
}

.dlg-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dlg-diagram-fullbox {
  width: 100%;
  height: 249px;
}

.dlg-diagram-halfbox {
    width: 50%;
    height: 249px;
}

.dlg-diagram-image {
    height:100%;
    width: 100%;
    object-fit: contain;
}

.banner-span {
  background-color: rgb(255, 255, 255);
  height: 33px;
  display: block;
  border-bottom: 1px solid #e1e1e1;
}

.collapsable {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 20px;
  transition: height 150ms ease-in-out;
}

.collapse-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 50px;
  background-color: whitesmoke;
  overflow-y: auto;
}

.collapse-header > h1 {
  text-transform: uppercase;
  transition: visibility 75ms ease-in-out;
}

.account-section {
  margin: 20px 15px;
  padding: 5px 10px;
  border: 2px solid #dbdbdb;
  font-weight: bold;
}

.account-section > h4 {
  font-size: 100%;
  font-weight: bold;
  margin-top: 0;
}

.literal-list {
    max-width: 300px;
    padding-left: 5px;
    list-style: none;
    font-size: 80%;
    color: rgb(116, 117, 117);
    text-shadow: 0px 0px 1px rgb(116, 117, 117);
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;
    border-bottom: 1px solid #d1d1d1;
    border-top: 1px solid #d1d1d1;
}

.literal-list::-webkit-scrollbar {
    width: 5px;
}
.literal-list::-webkit-scrollbar-track {
    background-color: #d1d1d1;
}
.literal-list::-webkit-scrollbar-thumb {
    background-color: rgb(116, 117, 117);
}

.semantic-equivalencer {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 5px;
    box-shadow: 1px 1px 5px 1px #d2d2d2aa;
}

.semantic-equivalencer label {
    font-size: 85%;
    color: #565656;
}

.semantic-equivalencer button {
    min-width: fit-content !important;
    min-width: -moz-fit-content !important;
}

.semantic-equivalencer:hover .sm-eq-placeholder {
    opacity: 0.9;
}

.se-configure-button {
    position: absolute;
    right: 5px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.sm-eq-placeholder {
    padding: 10px;
    padding-top: 25px;
    text-align: center;
    color: #707070;
    position: absolute;
    background-color: white;
    opacity: 0;
    transition: opacity 200ms linear;
}


.phenom-span-text {
    font-size: 90%;
    color: #565656;
    transition: opacity 300ms, max-height 300ms linear;
}

.react-modal {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #000000c7;
    padding: 140px 300px;
}

.react-modal > div {
    box-shadow: 10px 10px 5px #0000005e;
}

.fading-directions {
    background-color: #e9e9e9;
    position: absolute;
    height: 85%;
    max-height: 450px;
    width: 85%;
    left: 8%;
    top: 8%;
    z-index: 5;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #413a5f;
    padding: 11%;
    border-radius: 15px;
    box-shadow: 5px 5px 2px 2px #d9d9d9b3;
    transition: opacity 100ms linear;
}

#site-purple-nav {
    top: 0;
    background: radial-gradient(circle, #3f347f, #413D64);
    width: 76px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

#nav-content {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    position: sticky;
    top: 18px;
    min-height: 100vh;
}

#nav-content .nav-link {
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 85%;
    opacity: 0.7;
    transition: opacity .25s linear;
}

#nav-content .nav-link:after {
  display: block;
  content: attr(data-title);
  padding: 2px;
  position: relative;
  text-align: center;
  font-weight: 800;
  color: #aa91ab;
  opacity: 0;
  top: 1px;
  transition: opacity .25s linear;
}

#nav-content .nav-link:hover {
  opacity: 1;
}

#nav-content .nav-link:hover:after {
  opacity: 1;
}

#nav-content .active-nav{
    opacity: 1;
}

#nav-content .active-nav img {
    filter: brightness(1.15);
}

#site-purple-nav .hov-txt {
    padding: 2px;
    position: relative;
    text-align: center;
    font-weight: 800;
    color: #aa91ab;
    opacity: 0;
    top: 1px;
    transition: opacity .25s linear;
}

#site-purple-nav a:not(.active-nav):hover .hov-txt  {
    opacity: 1;
}

.tree-replacer {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
    background: #f1f1f1;
    display: none;
    border-right: 1px solid #e8e9ea;
}

#main-main-div {
    position: relative;
    display: flex;
    flex-grow: 1;
}

@-moz-document url-prefix() {
    .tree-container {
        min-height: calc(100vh - 309px);
    }
}

.load-button-wrapper {
    position: relative;
    width: fit-content;
    width: -moz-fit-content;
    height: 52px;
}

.load-button-wrapper > img {
    width: 70px;
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 37px);
    /* z-index: 1; */
}

.load-button-wrapper > button {
    /* margin-bottom: 10px; */
    /* margin-top: 10px; */
    margin: 10px 0px;
    /* z-index: 2; */
    position: relative;
}

.load-button-wrapper > button:disabled {
    color: darkgray;
}

.load-button-wrapper > button:disabled:hover {
    background-color: gray;
    border-color: gray;
}

.franken-wrapper {
    width: 50%;
    padding-left: 2px;
    margin-top: 10px;
    position: sticky;
    top: 15px;
    height: fit-content;
    height: -moz-fit-content;
}

.blue-nav {
    padding-left: 10px;
    background-color: #8aa3b2;
    height: 35px;
}



.detail-card {
  position: relative;
  padding: 5px 10px;
  color: #fff;
  background: #333;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-card .detail-card {
  margin-left: 50px;
}

.detail-card-type {
  font-size: 13px;
  margin-bottom: 5px;
}

.detail-card-label {
  word-wrap: wrap;
  font-weight: bold;
  margin-bottom: 5px;
}

.detail-card-body {
  font-size: 14px;
  font-style: italic;
  color: "#e2e2e2";
  margin-top: 10px;
}

.deletion-table {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
}

.deletion-table th {
    font-weight: bold;
}