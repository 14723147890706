#event-log {
  --color-error: #af1717;
  --color-warning: #f37a4c;
  --color-success: #1fa150;
  --color-progress: #403873;
  --resize-color: #f3f3f3;
  display: none;
  flex-direction: column;
  height: 150px;
  border-top: 1px solid #d0d0d0;
  background-color: #e7e8e9;
}

#event-log.visible {
  display: flex;
}

#event-log header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 0 0.25em;
  width: 100%;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ccc;
}


#event-log header ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 2;
}

#event-log header .title {
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 0;
}

#event-log-content {
  padding: 0 0.5em;
  overflow: auto;
}

.event-error,
#event-log button.event-error {
  color: var(--color-error);
}

.event-warning,
#event-log button.event-warning {
  color: var(--color-warning);
}

.event-success,
#event-log button.event-success {
  color: var(--color-success);
}

.event-progress,
#event-log button.event-progress {
  color: var(--color-progress);
}

#event-log button {
  background-color: none;
  border: none;
}

#event-log button:hover,
#event-log button:active {
  background-color: #e3e3e3;
}

#event-log button.event-error:hover,
#event-log button.event-error.active {
  color: white;
  background-color: var(--color-error);
}

#event-log button.event-warning:hover,
#event-log button.event-warning.active {
  color: white;
  background-color: var(--color-warning);
}

#event-log button.event-sucess:hover,
#event-log button.event-sucess.active {
  color: white;
  background-color: var(--color-sucess);
}

#event-log button.event-progress:hover,
#event-log button.event-progress.active {
  color: white;
  background-color: var(--color-progress);
}