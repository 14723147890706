/*--------------------------------------------------------------
# Phenom Modes
--------------------------------------------------------------*/
img.navbar-icon,
svg.navbar-icon {
  width: 32px;
  height: 32px;
}

.navigation-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.7em;
  width: 76px;
  /* background: radial-gradient(circle, #3f347f, #413D64); */
  background: hsl(var(--skayl-violet-hs) 30%);
  min-height: 0;
}

.primary-modes,
.secondary-modes {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  padding: 1em 0;
  margin: 0;
  list-style: none;
}

.mode-link {
  display: block;
  position: relative;
  width: 100%;
  opacity: 0.5;
  border: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  transition: opacity .25s linear;
}

.mode-link span {
  display: block;
  padding: 2px;
  position: relative;
  text-align: center;
  font-weight: 800;
  color: #aa91ab;
  opacity: 0.5;
  top: 1px;
  transition: opacity 300ms linear;
}

.mode-link:hover,
.mode-link:hover:after,
.mode-link.active,
.mode-link.active::after {
  opacity: 1;
  text-decoration: none;
}

.mode-event-log-container {
  position: relative;
  padding-top: 0.75em;
}



.perfect-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  min-width: 2em;
  padding: 0.25em;
  border-radius: 50%;
}

.event-log-dot {
  font-size: 11px;
  position: absolute;
  top: 0;
  right: 0.5em;
  color: white;
  background: red;
  border: 2px solid rgb(63, 52, 127);
}

.perfect-circle:before {
  content: "";
  float: left;
  width: auto;
  padding-bottom: 100%;
}

.perfect-circle.typer-icon {
  font-size: 10px;
  min-width: 1.5em;
  padding: 0;
  border: 1px solid black;
}

