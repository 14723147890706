[class^="sub-menu"] .nav-icon {
  width: 1.65em;
  padding: 0;
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.img-icon {
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.custom-modeltree-button-icon {
  display: block;
  padding: 0;
  border: none;
  box-sizing: border-box;
}

.icon-manager {
  background-image: url('../../images/icons/project_manager_white.png');
}

.icon-manager.active {
  background-image: url('../../images/icons/project_manager_purple.png');
}

.icon-push {
  background-image: url('../../images/icons/push_white.png');
}

.icon-push.active {
  background-image: url('../../images/icons/push_purple.png');
}

.icon-pull {
  background-image: url('../../images/icons/pull_white.png');
}

.icon-pull.active {
  background-image: url('../../images/icons/pull_purple.png');
}

.icon-approve {
  background-image: url('../../images/icons/approve_white.png');
}

.icon-approve.active {
  background-image: url('../../images/icons/approve_purple.png');
}

.icon-create-filtered-project {
  background-image: url('../../images/icons/create_filtered_project.png');
}

.icon-edit-filtered-project {
  background-image: url('../../images/icons/edit_filtered_project.png');
}