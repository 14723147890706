.message-field-contents {
    padding: 4px;
    transition: height 300ms linear, padding 300ms linear, opacity 200ms linear;
}

.message-field-contents .cadet-select {
    margin: 0 0 0 15px;
    padding: 0;
}

.message-field-contents .cadet-select, .message-field-contents .cadet-text-input {
    font-size: 100%;
    width: calc(100% - 100px);
    height: 20px;
}

.message-field-contents > section {
    display: flex;
}

.message-field-contents label {
    flex-grow: 2;
    display: flex;
    font-weight: normal;
    font-size: 95%;
    margin-bottom: 2px;
    color: #565656;
    text-shadow: 0px 0px 0px #565656d1;
}

.message-field-contents label:hover {
    color: #282828;
    text-shadow: 0px 0px 0px #282828d1;
}

.message-field-contents .fixed-label {
    width: 63px;
}

.message-field-contents .cadet-text-input {
    margin: 0 0 0 15px;
    padding: 0;
}

.message-field-contents .cadet-select:enabled:hover,
.message-field-contents .cadet-text-input:enabled:hover,
.message-field-contents .cadet-select:enabled:focus,
.message-field-contents .cadet-text-input:enabled:focus,
.message-field-header .cadet-select:enabled:hover,
.message-field-header .cadet-text-input:enabled:hover,
.message-field-header .cadet-select:enabled:focus,
.message-field-header .cadet-text-input:enabled:focus,
.conditional-message-header .cadet-text-input:enabled:hover,
.conditional-message-header .cadet-text-input:enabled:focus
{
    margin-top: -1px;
    margin-bottom: 1px;
}

.message-field-contents .cadet-select:enabled:hover,
.message-field-contents .cadet-text-input:enabled:hover,
.message-field-contents .cadet-select:enabled:focus,
.message-field-contents .cadet-text-input:enabled:focus {
    margin-left: 14px;
    margin-right: 0;
}

.message-field-header .cadet-select:enabled:hover,
.message-field-header .cadet-text-input:enabled:hover,
.message-field-header .cadet-select:enabled:focus,
.message-field-header .cadet-text-input:enabled:focus,
.conditional-message-header .cadet-text-input:enabled:hover,
.conditional-message-header .cadet-text-input:enabled:focus
 {
    box-shadow: 1px 1px 2px 1px #c5c5c5;
    margin-left: -1px;
    margin-right: 5px;
}

.message-field-header, .conditional-message-header {
    display: flex;
    background: #dadada;
    padding: 4px;
}

.conditional-message-header {
    justify-content: space-between;
    padding-right: 2px;
}

.message-field-header .cadet-text-input, .message-field-header .cadet-select, .conditional-message-header .cadet-text-input {
    font-size: 100%;
    margin: 0 4px 0 0;
    padding: 0;
}

.message-field-header .cadet-text-input,
 .message-field-header .cadet-select,
 .conditional-message-header .cadet-text-input
{
    box-shadow: 1px 1px 5px 1px #c9c9c9;
}

.message-field-header .cadet-text-input {
    width: 30%;
}

.conditional-message-header .cadet-text-input {
    width: calc(30% - 46px);
}

.message-field-header .cadet-select {
    width: 15%;
}

#mdm-trash {
    position: sticky;
    height: 50px;
    top: 120px;
    opacity: .6;
}

.new-mdm-field{
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border: 2px dashed #409b9e;
    color: #409b9e;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    background-color: #fff;
    margin-top: 5px;
    transition: all 150ms linear;
}

.new-mdm-field:hover {
    text-shadow: 0 0 4px #409b9e;
    box-shadow: inset 0 0 3px 1px #409b9e;
}

.conditional-name-entry {
    position: absolute;
    left: 0px;
    width: 250px;
    padding: 19px;
    background-color: white;
    border: 1px solid #dbdbdb;
    box-shadow: 2px 2px 3px 3px #dbdbdb94;
    z-index: 1;
}

.conditional-name-entry > .cadet-text-input {
    width: 100%;
    margin-bottom: 10px;
    z-index: 1;
}
