/* React-Transition-Group */


.grow-appear,
.grow-enter {
  transform: scaleY(0);
}

.grow-appear-active,
.grow-enter-active {
  transform: scaleY(1);
	transition: transform 300ms linear;
}

.grow-exit {
	transform: scaleY(1);
}

.grow-exit-active {
	transform: scaleY(0);
	transition: transform 300ms linear;
}


/* FADE EFFECT */
.fade-appear,
.fade-enter {
	opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms linear;
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms linear;
}


/* FADE-IN LEFT EFFECT */
.fadeUp-appear,
.fadeUp-enter {
	opacity: 0;
  transform: translateY(100%);
}

.fadeUp-appear-active,
.fadeUp-enter-active {
	opacity: 1;
  transform: translateY(0);
	transition: opacity 300ms linear, transform 300ms ease-in;
}

.fadeUp-exit {
	opacity: 1;
  transform: translateY(0);
}

.fadeUp-exit-active {
	opacity: 0;
  transform: translateY(100%);
	transition: opacity 300ms linear, transform 300ms ease-in;
}


/* FADE-IN LEFT EFFECT */
.fadeLeft-appear,
.fadeLeft-enter {
	opacity: 0;
  transform: translateX(100%);
}

.fadeLeft-appear-active,
.fadeLeft-enter-active {
	opacity: 1;
  transform: translateX(0);
	transition: opacity 300ms linear, transform 300ms ease-in;
}

.fadeLeft-exit {
	opacity: 1;
  transform: translateX(0);
}

.fadeLeft-exit-active {
	opacity: 0;
  transform: translateX(100%);
	transition: opacity 300ms linear, transform 300ms ease-in;
}


