/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Third Party Libraries
# Old CSS
# Variables
# Typography
# Site
# Navigation
	## Links
	## Menus
# Buttons
# Forms
# Widgets
# App
--------------------------------------------------------------*/



/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@import "./normalize.css";



/*--------------------------------------------------------------
# Third Party Libraries
--------------------------------------------------------------*/
@import "./thirdparty/bootstrap/bootstrap.css";
@import "./thirdparty/kendo/all.css";

/* WHAT IS THIS? */
@import "./circos-elements.css";

/* REMOVE??? used by NavTree tags??? */
@import "./thirdparty/jquery-ui/jquery-ui.css";
@import "./thirdparty/jquery-ui/jquery-ui.structure.css";
@import "./thirdparty/jquery-ui/jquery-ui.theme.css";

/* REMOVE??? */
@import "./thirdparty/jstree-themes/default/style.min.css";
@import "./thirdparty/codemirror/codemirror.css";
@import "./thirdparty/rappid/rappid.css";
@import "./thirdparty/rappid/style.css";
@import "./tagmanager/tagmanager.css";



/*--------------------------------------------------------------
# Old CSS
--------------------------------------------------------------*/
@import "./site.css";
@import "./tables.css";
@import "./wrappers.css";
@import "./forms-and-inputs.css";
@import "./buttons.css";
@import "./mdm.css";
@import "./SansPro.css";
@import "./diagrams.css";



/*--------------------------------------------------------------
# Variables
--------------------------------------------------------------*/
@import "./site/colors.css";



/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "./site/typography.css";



/*--------------------------------------------------------------
# Site
--------------------------------------------------------------*/
@import "./site/site.css";
@import "./site/transitions.css";



/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
@import "./navigation/modes.css";
@import "./navigation/sub-menu.css";



/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
@import "./buttons/buttons.css";
@import "./buttons/icons.css";



/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
@import "./layout/kendo.css";
@import "./layout/layout.css";
@import "./layout/inputs.css";
@import "./layout/tables.css";



/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
@import "./widgets/navigate.css";
@import "./widgets/model-stats.css";
@import "./widgets/skoogle.css";



/*--------------------------------------------------------------
# App
--------------------------------------------------------------*/
@import "./app/dashboard.css";
@import "./app/manage.css";
@import "./app/detail.css";
@import "./app/tree.css";
@import "./app/meridian_map.css";
@import "./app/event_log.css";



/*--------------------------------------------------------------
# CSS no longer in use
--------------------------------------------------------------*/
.btn-reset {
	margin-left: 10px;
	margin-bottom: -120px;
}

.right-nav-buttons a:active {
  color: #fff;
}

.row.text-left {
	padding-right: 15px;
	padding-left: 15px;
}