/*--------------------------------------------------------------
INDEX
# Menu
## Top Menu
# Search
## Side Buttons
# Filter
## Filter by tags
# ReacTree
# Collapsed
--------------------------------------------------------------*/
.navtree {
  --primary-hs: var(--skayl-violet-hs);
  --secondary-hs: var(--skayl-purple-hs);
  --resize-color: hsl(var(--secondary-hs) 65%);
  --collapsed-width: 20px;

  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 230px;
  color: #333333;
  background-color: #fff;
  min-height: 0px;
}

.navtree-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.navtree button {
  padding: 0.25em;
  border: none;
  background-color: transparent;
}

.navtree-menu,
.navtree-search {
  display: flex;
  position: relative;
  /* align-items: center; */
  font-size: 0.875em;
  padding: 0 0.5em;
  gap: 0.5em;
  color: white;
  background: hsl(var(--primary-hs) 30%);
  min-height: 2.5rem;
}

.navtree-search {
  color: hsl(var(--primary-hs) 30%);
  background: hsl(var(--secondary-hs) 65%);
  min-height: 2rem;
}

.navtree.collapsed {
  --collapsed-width: 30px;
  width: var(--collapsed-width) !important;
  min-width: auto;
}



/*--------------------------------------------------------------
# Menu
--------------------------------------------------------------*/
.navtree.collapsed .navtree-menu {
  padding: 0 0.25em;
}

.navtree-menu button {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.navtree-menu button:hover {
  background: hsl(var(--secondary-hs) 50%);
}

/* reduce the icon size */
.navtree.collapsed .navtree-menu button {
  font-size: 0.75em;
}

.navtree.collapsed .navtree-menu button > span {
  display: none;
}



/*--------------------------------------------------------------
## Top Menu
--------------------------------------------------------------*/
#tree-create-menu {
  display: flex;
  flex: 1;
}

#tree-create-dropdown {
  display: none;
}

#tree-create-dropdown.visible {
  display: block;
}

.tree-create-options,
.tree-create-options ul {
  position: absolute;
  font-size: 14px;
  inset: 100% auto auto 0;
  padding: 0;
  margin: 0;
  /* background: black; */
  list-style-type: none;
  white-space: nowrap;
  z-index: 1;
}

.tree-create-options li {
  position: relative;
  color: #f2f2f2;
  cursor: default;
  border-bottom: 1px solid #565656;
  border-left: 1px solid #565656;
  border-right: 1px solid #565656;
  background-color: hsl(var(--primary-hs) 57%);
  box-sizing: content-box;
}

.tree-create-options li:last-child {
  border-bottom: none;
}

.tree-create-options span,
.tree-create-options a {
  display: block;
  padding: 0.4em;
}

.tree-create-options span:hover,
.tree-create-options a:hover {
  color: #f2f2f2;
  text-decoration: none;
  background-color: hsl(var(--secondary-hs) 50%);
}

.tree-create-options ul {
  visibility: hidden;
  position: absolute;
  padding: 1.5em 1.5em 1.5em 0;
  inset: -1.5em auto auto 100%;
}

.tree-create-options li:hover > ul {
  visibility: visible;
}

.tree-create-options-dropdown i {
  float: right;
  font-size: 18px;
  position: relative;
  bottom: 0.5px;
}


/*--------------------------------------------------------------
# Search
--------------------------------------------------------------*/
.tree-search {
  display: flex; 
  align-items: center; 
  position: relative; 
  flex-wrap: wrap; 
  flex: 1; 
  gap: 6px;
}

.tree-search input {
  display: block;
  width: 100%;
  flex: 1;
  color: hsl(var(--primary-hs) 30%);
  outline: none;
  border: none;
  background: transparent;
}

.tree-search i {
  cursor: pointer;
  padding: .25em;
}

.tree-search i.active {
  /* color: white; */
  color: hsl(var(--secondary-hs) 65%);
  background-color: hsl(var(--primary-hs) 30%);
}



/*--------------------------------------------------------------
## Side Buttons
--------------------------------------------------------------*/
.navtree-side-btns {
  display: flex;
  flex-direction: column;
  background-color: hsl(var(--secondary-hs) 65%);
}

.navtree-side-btns button:hover,
.navtree-side-btns button.active {
  color: hsl(var(--secondary-hs) 65%);
  background-color: hsl(var(--primary-hs) 30%);
}

.navtree-collapse-btn {
  flex: 1;
  min-width: var(--collapsed-width);
  font-family: var(--font-kendo-icon);
  background-color: hsl(var(--secondary-hs) 65%);
}

.navtree-collapse-btn:before {
  content: "\e016";
}

.navtree.collapsed .navtree-collapse-btn:before {
  content: "\e014";
}

.navtree-side-btns .navtree-collapse-btn:hover {
  color: white;
  font-weight: bold;
  background-color: inherit;
}



/*--------------------------------------------------------------
# Filter
--------------------------------------------------------------*/
.navtree-filter {
  --color-border: #333;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: white;
}

.tree-content .navtree-filter header {
  display: flex;
  border-bottom: 1px solid var(--color-border);
  justify-content: flex-start;
  align-items: unset;
  padding: 0;
}

.navtree-filter header h1 {
  font-size: 1em;
  padding: 0.5em;
  margin: 0;
  width: auto;
  text-transform: none;
}

.navtree-filter h2 {
  margin: 0 0 0.5em;
  text-transform: none;
}

.navtree-filter header button {
  padding: 0.45em;
  background: transparent;
  border: none;
}

.navtree-filter header button.active,
.navtree-filter header button:hover {
  background: hsl(var(--secondary-hs) 65%);
  font-weight: 600;
}

.navtree-filter header button:last-child {
  border-right: none;
}



/*--------------------------------------------------------------
## Filter by tags
--------------------------------------------------------------*/
.navtree-filter-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0.5em 1em;
}

.navtree-filter-actions .filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.navtree-sm .filter-buttons {
  flex-direction: column-reverse;
  gap: 10px;
}

.navtree-filter-actions .filter-btn  {
  padding: 0.35em;
  margin: 10px;
  color: #020202;
}

.navtree-filter-actions label input {
  margin: 0 3px 0 0;
}


.navtree-filter-tags {
  display: flex;
  flex-wrap: wrap;
}

.navtree-filter-list {
  position: relative;
  flex: 1 1 138.5px;
  padding: 1em 0.5em;
  overflow: hidden;
}

.navtree-filter-list ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0.5em 0 0;
  list-style: none;
}

/* Tag Icon */
.navtree-filter-list button {
  display: inline-block;
  padding: 0.35em 0.5em;
  border-radius: 10px;
  background: #e3e3e3;
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  overflow:hidden;
}

.navtree-filter-list button:hover {
  background: #ffadad;
}

#phenom-navtree button:hover .fa-tag:before,
#phenom-navtree button:hover .fa-paperclip:before {
  content: "\f057";
}









/*--------------------------------------------------------------
# ReacTree
--------------------------------------------------------------*/
.tree-content {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 0px;
  overflow: hidden;
}

.tree-content-wrapper {
  display: flex;
  position: relative;
  flex: 1;
  font-size: 0.8em;
  min-height: 0px;
  overflow: auto;
  scroll-behavior: smooth;
}

.tree-content.vertical,
.tree-content-wrapper.vertical {
  flex-direction: column;
}

.tree-content header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  padding-right: 0.5em;
}

.tree-content header button {
  border-radius: 2px;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}

.tree-left-header{
  display: flex;
  align-items: center;
  position: relative;
}

.tree-left-header button{
  margin-left: 4px;
}

.tree-left-header button:hover{
  background-color: rgba(0, 0, 0, 0.08);
}

.tree-content header h2 {
  font-weight: 900;
  text-transform: inherit;
  margin: 0;
}

.tree-content ul {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 1.5em;
  list-style-type: none;
}

.tree-content li {
  position: relative;
}

.tree-node {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1px 5px;
  gap: 5px;
  min-height: 2em;
  cursor: default;
  color: rgb(116, 117, 117);
  user-select: none;
}

.tree-content [class*="fa-caret-"] {
  cursor: pointer;
}

.tree-node-caret {
  display: block;
  position: absolute;
  padding: 0 5px;
  left: -16px;
}

.tree-node .img-icon {
  width: 23px;
  padding: 1px;
  box-sizing: content-box;
}

.tree-node .fa-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 23px;
  height: 23px;
  padding: 1px;
  box-sizing: content-box;
  overflow: hidden;
}

.tree-node.under-review {
  background-color: rgb(255, 255, 200);
}

.tree-node.accepted {
  background-color: rgb(200, 255, 200);
}

.tree-node.rejected {
  background-color: rgb(255, 200, 200);
}

.tree-node:hover,
.tree-node.auto-selected {
  background-color: rgb(231, 244, 249);
}

.tree-node.auto-selected {
  font-weight: 900;
}

.tree-node.selected {
  background-color: rgb(179, 232, 255);
}

.tree-node.mergeable {
  color: #489bb3;
  font-weight: 900;
}

.tree-node.mergefiltered {
  color: rgb(253, 166, 78);
  font-weight: 900;
}

.tree-node.mergemoved {
  color: #833b83;
  font-weight: 900;
}

.tree-node.created {
  color: #4f9c54;
  font-weight: 900;
}

.tree-node.highlighted {
  color: rgb(139, 0, 0);
  font-weight: 900;
  font-style: italic;
}

.tree-node.unreachable {
  color: rgb(139, 0, 0);
  font-weight: 900;
}

.tree-node.crossed-out {
  text-decoration: line-through;
}










.loading-search {
	float: left;
	margin-left: 8px;
	padding-bottom: 4px;
	width: 30px;
	display: inline-block;
}




/*--------------------------------------------------------------
# Collapsed
--------------------------------------------------------------*/
/* #phenom-navtree.collapsed {
  --collapsed-width: 30px;
  width: var(--collapsed-width) !important;
  min-width: auto;
} */

/* reduce the icon size */
/* #phenom-navtree.collapsed #navtree-actions button {
  flex: 1;
  font-size: 0.75em;
} */

/* #phenom-navtree.collapsed #navtree-actions button > span,
#phenom-navtree.collapsed #navtree-tools,
#phenom-navtree.collapsed #navtree-buttons button,
#phenom-navtree.collapsed #navtree-content,
#phenom-navtree.collapsed #navtree-resize {
  display: none;
} */






/* DELETE ME */
.navtree-content-wrapper.old-tree {
  display: flex;
  flex-direction: column;
}
