/*--------------------------------------------------------------
# Index
00. Root
01. Toolbar
02. Stencil
05. Node
06. Node Attribute
07. Anchor Points
08. Links
15. Prompt
20. Side Panel
50. Scratchpad
60. IDM Editor
--------------------------------------------------------------*/



/*--------------------------------------------------------------
00. Root
--------------------------------------------------------------*/
/* .srd-demo-canvas {
  height: 100%;
  cursor: auto !important;
} */

.storm-diagram {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
      "header header sidebar"
      "stencil canvas sidebar";
  flex: 1;
  overflow: hidden;
}

.storm-header {
  grid-area: header;
}

.storm-canvas {
  grid-area: canvas;
  overflow: hidden;
}

.storm-sidebar {
  grid-area: sidebar;
  background: #eee;
}

.storm-container {
  top: 0;
  position: relative;
  /* height: calc(100vh - 259px); */
  height: 100%;
  width: 100%;
  right: 0;
  overflow: auto;
}

.svg-on-top > svg {
  z-index: 1;
}



/*--------------------------------------------------------------
01. Toolbar
--------------------------------------------------------------*/
.diagram-toolbar {
  --main-color: #6b6c8a;
  --secondary-color: #fff;
  display: flex;
  gap: 4px;
  padding: 18px 10px 4px;
  font-size: 12px;
  color: var(--main-color);
  background: hsl(0 0% 96%);
}

.toolbar-separator {
  width: 1px;
  background: #b3b3b3;
}

.toolbar-icon-btn,
.toolbar-text-btn {
  font-size: var(--font-size);
  padding: 6px;
  border: none;
  outline: none;
  background: transparent;
}

.toolbar-icon-btn:hover,
.toolbar-text-btn:hover {
  color: var(--secondary-color);
  background: var(--main-color);
}

.toolbar-icon-btn {
  --font-size: 20px;
  border: none;
  background: transparent;
}

.toolbar-text-btn {
  --font-size: 11px;
  display: block;
  border: 1px solid var(--main-color);
  white-space: nowrap;
}

.toolbar-section {
  display: flex;
  align-items: center;
  position: relative;
  gap: 2px;
}

.toolbar-section:before {
  position: absolute;
  content: attr(data-title);
  width: 100%;
  top: -14px;
  text-align: center;
  font-style: italic;
}

.toolbar-section .toggle #first {
  height: 16px;
  width: 16px;
  margin-top: -2px;
}

.toolbar-section .toggle .tglLeft {
  right: calc(100% - 16px);
}

/*--------------------------------------------------------------
# Zoom Section
--------------------------------------------------------------*/
.toolbar-section-zoom {
  gap: 2px;
  justify-content: center;
  width: 150px;
}

.toolbar-section-zoom > button {
  font-size: 14px;
  padding: 4px;
}

.toolbar-section-zoom > output {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
}

.toolbar-section-zoom input[type="range"] {
  vertical-align: middle;
  width: 80px;
  color: #ddd;
  background: transparent;
  position: relative;
  appearance: none;
  height: 20px;
  border: none;
  outline: none;
  padding: 0;
}

.toolbar-section-zoom input[type="range"]:focus {
  outline: none;
}

.toolbar-section-zoom input[type="range"]:after {
  position: absolute;
  left: 0;
  top: 9px;
  content: ' ';
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #fb6eb6, #c658fd);
  border-radius: 3px;
}

.toolbar-section-zoom input[type="range"]::-moz-range-track {
  position: absolute;
  left: 0;
  top: 9px;
  content: ' ';
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #fb6eb6, #c658fd);
  border-radius: 3px;
}

.toolbar-section-zoom input[type="range"]::-ms-track {
  position: absolute;
  left: 0;
  top: 9px;
  content: ' ';
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #fb6eb6, #c658fd);
  border-radius: 3px;
}

.toolbar-section-zoom input[type="range"]::-ms-thumb {
  position: relative;
  width: 8px;
  height: 8px;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 1px 1px 1px #888;
  border-radius: 8px;
  appearance: none;
}

.toolbar-section-zoom input[type="range"]::-moz-range-thumb {
  position: relative;
  width: 8px;
  height: 8px;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 1px 1px 1px #888;
  border-radius: 8px;
  appearance: none;
}

.toolbar-section-zoom input[type="range"]::-webkit-slider-thumb {
  position: relative;
  width: 8px;
  height: 8px;
  top: 0;
  z-index: 2;
  background: #fff;
  box-shadow: 1px 1px 1px #888;
  border-radius: 8px;
  appearance: none;
}



/*--------------------------------------------------------------
02. Stencil Box
--------------------------------------------------------------*/
.storm-stencilbox {
  --stencilbox-width: 100px;
  --background-color: hsl(220, 46%, 95%);
  grid-area: stencil;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 5px;
  padding: 10px;
  height: 100%;
  width: var(--stencilbox-width);
  overflow: hidden;
  background-color: var(--background-color);
}

.stencil-item {
  --bg-color: hsl(0 0% 0%);
  font-family: Helvetica, Arial;
  padding: 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background-color: var(--bg-color);
}

.stencil-blank {
  padding: 5px;
}






/*--------------------------------------------------------------
05. Node
--------------------------------------------------------------*/
.node-container,
.node-container-center {
  --width: 300px;
  --minWidth: 100px;
  --minHeight: 20px;
  --borderWidth: 0px;
  --nodeColor: hsl(var(--skayl-zero-hs) 0%);

  position: relative;
  width: var(--width);
  min-width: var(--minWidth);
  min-height: var(--minHeight);
  background: hsl(0, 0%, 93%);
}

.node-color-border {
  --borderWidth: 2px;
  border: var(--borderWidth) solid var(--nodeColor);
}

.node-font-color {
  --nodeFontColor: hsl(0, 0%, 95%);
  color: var(--nodeFontColor);
}

.node-color-background {
  background: var(--nodeColor);
}

.node-halo {
  display: block;
  position: absolute;
  inset: -14px;
  border: 1px solid skyblue;
  border-radius: 3px;
  z-index:-1;
}

.node-header {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  min-height: var(--minHeight);
}

.node-header > span {
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.node-input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  width: 100%;
  z-index: 1;
}

.node-input-head {
  padding: 0 10px;
}

.node-input-head:disabled {
  cursor: inherit;
  text-align: center;
}


/* Top Tool Bar */
.top-draw-icons {
  display: flex;
  position: absolute;
  gap: 10px;
  top: -40px;
  right: 0;
}

.top-draw-icons .k-icon {
  color: #717171;
  font-size: 24px;
  background: #f6f6f6;
  border-radius: 50px;
}


.selectable-node {
  /* --hs: 100 44% !important;
  --l: 44% !important; */
  --nodeColor: hsl(100 44% 44%) !important;
}

.selectable-node:hover {
  font-weight: 600;
}









/*--------------------------------------------------------------
06. Node Attribute
--------------------------------------------------------------*/
.attribute-container,
.attribute-table {
  --attrColor: hsl(var(--skayl-zero-hs) 93%);

  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
}

/* note: this has minHeight compared to the one above. */
.attribute-table {
  border: 2px solid var(--nodeColor);
  min-height: var(--minHeight);
  background: var(--attrColor);
}

.attribute-row {
  --rowColor: hsl(var(--skayl-zero-hs) 93%);
  --rowFontColor: hsl(246 24% 32%);
  
  display: flex;
  position: relative;
  flex: 1;
  color: var(--rowFontColor);
  background: var(--rowColor);
}

.attribute-row.assocentity-background {
  --rowColor: hsl(209, 100%, 91%);
}

.attribute-row.specializes-background {
  --rowColor: hsl(56, 89%, 90%);
}

.attribute-row.reverse {
  flex-direction: row-reverse;
}

/* Attribute Header */
.attribute-row.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;
  background: hsl(0, 0%, 93%);
}

.attribute-row.header > div {
  border-right: 1px solid gray;
}

.attribute-row.header > div:last-of-type {
  border-right: none;
  margin-right: 0;
}

.attribute-row.tall {
  height: 24px;
}

.attribute-cell {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
  box-sizing: border-box;
  overflow: hidden;
}

.attribute-row.header > .attribute-cell {
  padding: 0 5px;
  border-right: 1px solid gray;
}

.attribute-row.header > .attribute-cell:last-of-type {
  border-right: none;
}

.attribute-row.tall > .char-cell {
  font-size: 10px;
  padding: 0;
  text-align: center;
}

.attribute-cell.fixed {
  flex: none;
  justify-content: center;
  width: 50px;
  margin: 0;
}

.attribute-cell.check-box {
  width: 26px;
  overflow: inherit;
}

.attribute-cell.char-cell {
  width: 38px;
}

.attribute-cell.char-cell > button {
  font-size: 12px;
  height: 14px;
}

.selectable-row {
  color: hsl(100 51% 40%) !important;
}

.selectable-row:hover {
  font-weight: 600;
}

.attribute-circle-icon {
  border: 1px solid black;
  border-radius: 50%;
}



/*--------------------------------------------------------------
07. Anchor Points
--------------------------------------------------------------*/
/* ANCHOR POINTS */
.anchor-point-container {
  --anchor-size: 9px;
  --anchor-half-size: calc(var(--anchor-size) / 2);
  --anchor-half-size-neg: calc(var(--anchor-size) / -2);
  /* position: absolute; */
  /* inset: 0; */
}

.anchor-point-container.behind {
  z-index: -1;
}

.anchor-point {
  position: absolute;
  cursor: pointer;
}

.anchor-point-bullet {
  position: absolute;
  width: var(--anchor-size);
  height: var(--anchor-size);
  background: hsl(var(--skayl-zero-hs) 65%);
}

.anchor-point-label {
  position: absolute;
  font-size: 12px;
}

.anchor-point-container .top-left {
  top: var(--anchor-half-size-neg); 
  left: var(--anchor-half-size-neg);
}

.anchor-point-container .top { 
  top: var(--anchor-half-size-neg); 
  left: calc(50% - var(--anchor-half-size)); 
}

.anchor-point-container .top-right { 
  top: var(--anchor-half-size-neg); 
  right: var(--anchor-half-size-neg); 
}

.anchor-point-container .left { 
  top: calc(50% - var(--anchor-half-size)); 
  left: var(--anchor-half-size-neg); 
}

.anchor-point-container .right { 
  top: calc(50% - var(--anchor-half-size)); 
  right: var(--anchor-half-size-neg); 
}

.anchor-point-container .bottom-left { 
  bottom: var(--anchor-half-size-neg);
  left: var(--anchor-half-size-neg);
}

.anchor-point-container .bottom { 
  bottom: var(--anchor-half-size-neg);
  left: calc(50% - var(--anchor-half-size)); 
}

.anchor-point-container .bottom-right { 
  bottom: var(--anchor-half-size-neg);
  right: var(--anchor-half-size-neg);
}



/*--------------------------------------------------------------
08. Links
--------------------------------------------------------------*/
.base-link {
  --linkColor: gray;
}

.link-label {
  border-radius: 5px;
  color: rgba(136, 136, 136, .8);
  font-size: 12px;
  padding: 4px 8px;
  font-family: sans-serif;
  user-select: none;
  text-align: center;
}


/*--------------------------------------------------------------
15. Prompt
--------------------------------------------------------------*/
.storm-prompt-container {
  --flex-gap: 8px;
  background: white;
  position: relative;
  width: 600px;
}

.storm-prompt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
  background: #8aa5b2;
  color: white;
}

.storm-prompt-header h2 {
  font-size: 18px;
  margin: 0;
}

.storm-prompt-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
}

.storm-prompt-box,
.storm-prompt-box-200 {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.storm-prompt-box-200 {
  height: 200px;
}

.storm-prompt-selection {
  list-style: none;
  padding-left: 0;
  margin: 0;
  border: 1px solid darkgrey;
  border-radius: 2px;
}

.storm-prompt-selection-item:hover {
  background-color: rgb(232, 232, 232);
}

.storm-prompt-image {
  height: 100%;
  width:  100%;
  object-fit: contain;
}

.storm-prompt-label {
  display: block;
  font-weight: 400;
  margin: 0;
}

.storm-prompt-input {
  display: block;
  width: 100%;
}

.storm-prompt-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.storm-prompt-unsaved-button {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left;
  padding: 10px;
  width: 100%;
  color: #121212;
  cursor: pointer;
  border: 1px solid rgb(180, 180, 180);
  background: white;
}

.storm-prompt-unsaved-button:hover {
  color: white;
  background: hsl(var(--skayl-violet-hs) 40%);
}

.storm-prompt-unsaved-button:hover p {
  color: white;
}

.storm-prompt-unsaved-button:disabled {
  color: gray;
  background: gainsboro;
}

.storm-prompt-unsaved-button:disabled p {
  color: gray;
}

.storm-prompt-unsaved-button h3 {
  font-size: 18px;
  margin: 0;
}

.storm-prompt-unsaved-button p {
  color: gray;
  font-size: 12px;
  margin: 0;
}


/* TODO: REMOVE */
.storm-prompt-content-grid {
  display: grid;
  width: 600px;
  column-gap: 16px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;
}


/*--------------------------------------------------------------
20. Side Panel
--------------------------------------------------------------*/
.storm-side-panel {
  --resize-width: 3px;
  --edit-width: 250px;
  grid-area: sidebar;
  display: grid;
  position: relative;
  height: 100%;
  grid-template-columns: var(--resize-width) var(--edit-width) 20px;
  grid-template-areas:
    "resize edit tabs";
  overflow: hidden;
}

.storm-side-resize {
  grid-area: resize;
  display: flex;
  flex-direction: column;
  background: #ebecf0;
}

.storm-side-resize:hover {
  cursor: ew-resize;
  background: #2684ff;
}

.storm-side-edit {
  grid-area: edit;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: hsl(var(--skayl-zero-hs) 95%);
  overflow: hidden;
}

.storm-side-tabs {
  grid-area: tabs;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #ecf0f8;
}

.storm-side-tab-item {
  display: inline;
  font-size: 12px;
  padding: 10px 0;
  writing-mode: vertical-lr;
  line-height: 1.7;
  cursor: pointer;
}

.storm-side-tab-item.active,
.storm-side-tab-item:hover {
  background: #d2dbf7;
}




.storm-side-edit > section {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  /* width: 250px; */
  overflow: hidden;
}

/* .storm-side-edit label {
  margin: 0px;
} */

.storm-side-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px;
  background: hsl(var(--skayl-zero-hs) 35%);
}

.storm-side-content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 8px;
  gap: 10px;
  /* height: calc(100% - 42px); */
  flex: 1;
  overflow: auto;
}

.storm-side-content input,
.storm-side-content textarea,
.storm-side-content select {
  background-color: white;
}

.storm-side-content .cadet-label {
  --bgColor: #cdcdcd;
}

.storm-side-port-detail {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 10px;
  padding: 5px 8px;
  margin: 0 -8px;
  /* overflow-x: auto;
  overflow-y: hidden; */
}

.storm-side-port-detail:last-of-type {
  margin-bottom: 0px;
  border-bottom: none;
}

.storm-side-port-detail:nth-child(even) {
  background: hsl(var(--skayl-zero-hs) 90%);
}

.storm-side-divider:last-of-type {
  display: none;
}


.storm-side-delete-detail {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.2em;
  column-gap: 1em;
  padding: 10px;
  margin: 0 -8px;
  border-bottom: 2px solid hsl(var(--skayl-zero-hs) 80%);
}

.storm-side-delete-detail:last-of-type {
  margin-bottom: 0px;
  border-bottom: none;
}

.storm-side-delete-readd {
  width: 160px;
  grid-column: 2;
}

/*--------------------------------------------------------------
50. Scratchpad
--------------------------------------------------------------*/
.association-error {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  position: absolute;
  color: red;
  top: 0;
  right: 0;
  text-shadow: 1px 1px #000;
}

.association-error:before {
  content: "\f12a";
}

.hexagon {
  --hex-height: 0px;
  --hex-width: 5px;
  --hex-width-neg: calc(var(--hex-width) * -1);
  position: absolute;
  inset: 0;
  z-index: -1;
}

.hexagon-side-right,
.hexagon-side-left {
  position: absolute;
  top: 0;
  border-top: calc(var(--hex-height) / 2) solid transparent;
  border-bottom: calc(var(--hex-height) / 2) solid transparent;
}

.hexagon-side-right {
  right: var(--hex-width-neg);
  border-left: var(--hex-width) solid var(--nodeColor);
}

.hexagon-side-left {
  left: var(--hex-width-neg);
  border-right: var(--hex-width) solid var(--nodeColor);
}

.shape {
  --shape-thickness: 5px;
  --shape-height: 0px;
  --shape-width: 5px;
  position: absolute;
  inset: 0;
  z-index: -1;
}

/* .shape-right {
  position: absolute;
  top: 0;
  right: calc(var(--shape-thickness) * -1);

} */

.shape-circle {
  position: absolute;
  inset: -8px;
  background: var(--nodeColor);
  border-radius: 30px;
}

.shape-rhombus-right {
  position: absolute;
  top: calc(var(--borderWidth) * -1);
  right: calc(var(--shape-thickness) * -1 - var(--borderWidth));
  border-left: var(--shape-width) solid var(--nodeColor);
  border-bottom: var(--shape-height) solid transparent;
}

.shape-rhombus-left {
  position: absolute;
  top: calc(var(--borderWidth) * -1);
  left: calc(var(--shape-thickness) * -1 - var(--borderWidth));
  border-right: var(--shape-width) solid var(--nodeColor);
  border-top: var(--shape-height) solid transparent;
}



/*--------------------------------------------------------------
60. IDM Editor
--------------------------------------------------------------*/
.node-container-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.block-container {
  --width: 200px;
  --minHeight: 30px;
}

.block-attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.block-attribute > label {
  flex: 1 1 40%;
  margin: 0;
}

.block-attribute > input {
  flex: 1 1 60%;
}

.composed-block-container {
  --width: 30px;
  --minWidth: 30px;
  --minHeight: 30px;
}

.composed-border {
  --_position: absolute;
  --_thickness: 3px;
  --_bgcolor: lightblue;
  --_offset: 20px;
}

.top-composed-border {
  position: var(--_position);
  background-color: var(--_bgcolor);
  height: var(--_thickness);
  top: var(--_offset);
  right: var(--_offset);
  left: var(--_offset);
}

.right-composed-border {
  position: var(--_position);
  background-color: var(--_bgcolor);
  width: var(--_thickness);
  top: var(--_offset);
  right: var(--_offset);
  bottom: var(--_offset);
}

.bottom-composed-border {
  position: var(--_position);
  background-color: var(--_bgcolor);
  height: var(--_thickness);
  right: var(--_offset);
  bottom: var(--_offset);
  left: var(--_offset);
}

.left-composed-border {
  position: var(--_position);
  background-color: var(--_bgcolor);
  width: var(--_thickness);
  top: var(--_offset);
  left: var(--_offset);
  bottom: var(--_offset);
}




/*--------------------------------------------------------------
90. Drag and Drop
--------------------------------------------------------------*/
.drop-zone {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  justify-content: space-between;
  z-index: 1;
}

.leaf-drag .drop-zone {
  display: flex;
}