.cadet-table {
  border-collapse: separate;
  border-spacing: 0.5em 0;
}

.cadet-table thead {
  font-size: 1.25em;
}

.centered-col-header {
  text-align: center !important;
}


/*
 * ListPaneView
 */

/* ListPaneView header classes*/

.list-view-header {
  display: flex;
  color: #f2f2f2;
  text-align: center;
  justify-content: space-between;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0px;
  border: none;
  position: sticky; 
  top: 0px;
  z-index: 1;
}

.list-view-header.main {
  background-color: hsl(var(--skayl-violet-hs) 30%);
  padding-left: 2px;
}

.list-view-header {
  background-color: white;
  border-bottom: 1px solid gainsboro;
}

.list-view-header.header-selectable {
  cursor: pointer;
}

.list-view-header-main-column.main {
  padding: 5px; 
  gap: 0; 
  margin: 0px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.list-view-header-main-column,
.list-view-header-main-column {
  padding: 2px 5px; 
  gap: 0; 
  margin: 0px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.list-view-header-column.main {
  margin: 0px;
  padding: 5px; 
  gap: 0; 
  justify-content: center;
  display: flex;
  align-items: center;
}

.list-view-header-column,
.list-view-header-column {
  margin: 0px;
  padding: 2px 5px; 
  gap: 0; 
  justify-content: center;
  display: flex;
  align-items: center;
}

.list-view-header p,
.list-view-header p {
  color: rgb(86, 86, 86);
  font-weight: 700;
  font-size: 15px;
}

.list-view-header p {
  margin: 0;
}

.list-view-header .fas.fa-chevron-right,
.list-view-header .fas.fa-chevron-down {
  color: rgb(86, 86, 86);
}

.list-view-header.main .fas.fa-chevron-right,
.list-view-header.main .fas.fa-chevron-down {
  color: white;
}

/* ListPaneView row classes*/

.list-view-header.main p{
  color: white;
  font-weight: 700;
  font-size: 15px;
}

.list-view-header-main-column p {
  text-align: left;
  width: 100%;
}

.list-view-row {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 0px;
  border-bottom: 1px solid gainsboro;
}

.list-view-row.row-selectable {
  cursor: pointer;
}

.list-view-row:hover {
  background-color: #F5F5F5;
}

.list-view-row.selected {
  background-color: rgb(202, 239, 255);
}

.list-view-row p,
.list-view-row h3 {
  margin: 0px;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
  width: 100%;
}

.list-view-row ul {
  text-align: left;
  padding: 0;
  margin: 0;
}

.list-view-row-main-column {
  align-items: left;
  justify-content: center; 
  padding: 5px; 
  gap: 0; 
  min-width: 0px;
}

.list-view-row-column {
  align-items: center; 
  justify-content: center;  
  padding: 5px;
  margin: 0px;
  gap: 0;
  min-width: 0px;
  text-align: center;
}

p.Pending {
  background-color: rgb(255, 255, 200);
  display: inline-block;
  width: 7em;
  padding: 0 22px;
}

p.Accepted {
  background-color: rgb(200, 255, 200);
  display: inline-block;
  width: 7em;
  padding: 0 22px;
}

p.Rejected {
  background-color: rgb(255, 200, 200);
  display: inline-block;
  width: 7em;
  padding: 0 22px;
}