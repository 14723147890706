#skoogle-bar {
  position: relative;
  flex: 0 1 400px;
  z-index: 1;
}

#skoogle-bar.expanded {
  flex: 1 1;
}

.skoogle-backdrop {
  position: fixed;
  inset: 0 0 0 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
}

.skoogle-form {
  display: block;
}

.skoogle-form input {
  margin: 0 !important;
  background: white;
}

.skoogle-result {
  position: absolute;
  top: 100%;
  width: 100%;
}

